import {
    privacyPolicyURL,
    termsAndConditionsURL,
} from '@hazadapt-git/public-core-base'
import { Link } from '@mui/material'
import React, { FC } from 'react'

import { Checkbox } from '../atoms/'

interface TermsCheckboxProps {
    checked?: boolean
    onCheckboxPress(...props: any): void
    onTermsLinkPress(...props: any): void
    onPrivacyPolicyLinkPress(...props: any): void
}

export const TermsCheckbox: FC<TermsCheckboxProps> = (
    props: TermsCheckboxProps
) => (
    <Checkbox
        label={
            <>
                By creating a HazAdapt ID, you agree with our{' '}
                <Link
                    href={termsAndConditionsURL}
                    rel="noopener"
                    target="_blank"
                >
                    Terms of Service
                </Link>{' '}
                {'& '}
                <Link href={privacyPolicyURL} rel="noopener" target="_blank">
                    Privacy Policy
                </Link>
                .
            </>
        }
        onChange={props.onCheckboxPress}
        checked={props.checked}
    />
)
