import { Help, Info, Lock } from '@mui/icons-material'
import React, { FC } from 'react'
import { BsFillFileEarmarkBarGraphFill } from 'react-icons/bs'
import { IoImageOutline, IoLanguage } from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom'

import InfoCustomizationsGray from '../assets/icons/InfoCustomizations-Gray.svg'
import InfoCustomizationsTeal from '../assets/icons/InfoCustomizations-Teal.svg'
import {
    AboutTab,
    FAQBlockProps,
    FAQTab,
    ReportIssueTab,
    SettingsPageTemplate,
    TabGroupProps,
    LanguageTab,
    InformationCustomizationsTab,
    AccountTab,
    LoadingView,
} from '../components'
import {
    BoosterConfig,
    LanguageCode,
    LoggedPage,
    NativeLanguageDTO,
    SupportedContentBooster,
} from '@hazadapt-git/public-core-base'
import {
    switchLanguageThunk,
    addProfilePictureThunk,
    deleteProfilePictureThunk,
    saveProfileChangesThunk,
} from '../lib/slices'
import { useAppSelector, useAppDispatch, RootState } from '../lib/store'
import {
    customHazTealColor,
    customMidGrayColor,
    successColor,
    primaryIconSize,
    errorColor,
} from '../lib/styles/universal'
import {
    getLangObjects,
    goToAuthDashboard,
    signup,
    login,
    logEvent,
    openHazardRequestFormAsync,
    openPrivacyPolicyAsync,
    openReportABugFormAsync,
    openSubmitFeedbackFormAsync,
    logout,
    toast,
    useWindowSizeUp,
} from '../lib/utils'
import { ContentBooster, PageProps } from '../lib/entities'
import { readAsync, storeAsync } from '../lib/async-storage'
import { makeStyles } from 'tss-react/mui'

interface SettingsPageProps extends PageProps {}

export const SettingsPage: FC<SettingsPageProps> = (
    props: SettingsPageProps
) => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { classes: localClasses } = useLocalStyles()

    const mediumWindowOrLarger = useWindowSizeUp('md')

    const [activeGeneralTab, setActiveGeneralTab] = React.useState<number>(2)
    const [activeAboutUsTab, setActiveAboutUsTab] = React.useState<number>(3)
    const [activeTechSupportTab, setActiveTechSupportTab] =
        React.useState<number>(1)
    const [activeGroup, setActiveGroup] = React.useState<string>('General')
    const [selectedFAQ, setSelectedFAQ] = React.useState<number | undefined>()
    const [faqBlockPressed, setFaqBlockPressed] = React.useState<boolean>(false)
    const [boosters, setBoosters] = React.useState<BoosterConfig[]>([])
    const [selectedBoosters, setSelectedBoosters] = React.useState<
        SupportedContentBooster[]
    >([])

    const {
        language: selectedLanguage,
        user,
        profileReady,
        profilePictureURI,
    } = useAppSelector((state: RootState) => state.profile)
    const languages = useAppSelector(
        (state: RootState) => state.hazards.languages
    )

    React.useEffect(() => {
        if (user) {
            setSelectedBoosters([...user.preferred_content_boosters])
        } else {
            const storedBoosters = readAsync('preferred_content_boosters')
            //         let preferred_content_boosters: SupportedContentBooster[] = []
            if (storedBoosters) {
                const preferred_content_boosters = JSON.parse(storedBoosters)
                setSelectedBoosters(preferred_content_boosters)
            }
        }
    }, [user])

    React.useEffect(() => {
        if (mediumWindowOrLarger && selectedFAQ === undefined) {
            setSelectedFAQ(1)
        }
    }, [mediumWindowOrLarger, selectedFAQ])

    React.useEffect(() => {
        const boosterConfigs: BoosterConfig[] = Object.values(ContentBooster)
        setBoosters(boosterConfigs)
    }, [])

    React.useEffect(() => {
        if (!profileReady) return
        if (location.pathname === '/settings') {
            logEvent('OPEN_PAGE', { page: LoggedPage.SETTINGS })
            logEvent('OPEN_PAGE', { page: LoggedPage.ACCOUNT })
            document.title = 'My Account - HazAdapt'
            setActiveGeneralTab(2)
            setActiveGroup('General')
        } else if (
            location.pathname === '/settings/languages-and-customizations'
        ) {
            logEvent('OPEN_PAGE', { page: LoggedPage.LANGUAGES_CUSTOMIZATIONS })
            document.title = 'Languages and Customizations - HazAdapt'
            setActiveGroup('General')
            setActiveGeneralTab(1)
        } else if (location.pathname === '/settings/account') {
            logEvent('OPEN_PAGE', { page: LoggedPage.ACCOUNT })
            document.title = 'Account - HazAdapt'
            setActiveGroup('General')
            setActiveGeneralTab(2)
        } else if (location.pathname === '/settings/faq') {
            logEvent('OPEN_PAGE', { page: LoggedPage.FAQ })
            document.title = 'FAQ - HazAdapt'
            setActiveGroup('About Us')
            setActiveAboutUsTab(2)
        } else if (
            location.pathname === '/settings/about' ||
            location.pathname === '/settings/about-us'
        ) {
            logEvent('OPEN_PAGE', { page: LoggedPage.ABOUT })
            document.title = 'About Us - HazAdapt'
            setActiveGroup('About Us')
            setActiveAboutUsTab(3)
        } else if (
            location.pathname === '/settings/report-issue' ||
            location.pathname === '/settings/tech-support'
        ) {
            logEvent('OPEN_PAGE', { page: LoggedPage.REPORT_ISSUE })
            if (location.pathname === '/settings/report-issue') {
                document.title = 'Report Issue - HazAdapt'
            } else if (location.pathname === '/settings/tech-support') {
                document.title = 'Tech Support - HazAdapt'
            }
            setActiveGroup('Tech Support')
            setActiveTechSupportTab(1)
        }
    }, [location.pathname, profileReady])

    const faqs: FAQBlockProps[] = [
        {
            id: 1,
            question: "Why don't I see the hazard I'm looking for?",
            answer:
                "If you've searched for a hazard and do not immediately see the information you're looking for:" +
                '\n- Try rephrasing the query (i.e. instead of "skinned knee," try "cut" or "wound") to find better results.' +
                '\n- If you are currently in an emergency or are unsure if you are, do not hesitate to call 911.' +
                '\n- If you are in a non-life-threatening emergency situation, search for the information outside of HazAdapt.' +
                '\n\nThe Hazard Guide is an ever-growing resource of information, and it is possible we may not have the hazard information you need at this time. ' +
                'We prioritize the most common hazards and the most common emergency calls. ' +
                'Our goal is to offer information and strategies for every possible hazard with inclusive adaptations for kids, pets, and other social factors. ' +
                'It’s a journey of research and development but we are always getting closer to our goal! ' +
                'If there is a hazard you’d like to see represented in the Hazard Guide, let us know by tapping the Request a Hazard button below.',
            buttonTitle: 'Request a Hazard',
            onButtonPress: openHazardRequestFormAsync,
        },
        {
            id: 2,
            question:
                "I can't get connected to 911 on the phone. Can HazAdapt help?",
            answer:
                'At this time, HazAdapt cannot summon help to you. You can still refer to the information we provide while trying to connect to 911. ' +
                'The information we offer does not replace a trained first responder.',
        },
        {
            id: 3,
            question:
                'What is an effective way to get others to help me in an emergency?',
            answer:
                'Shout out to those around you: **"Help! This is an emergency! I need you to call 911!"** ' +
                'If you can, point out a person, make eye contact, and say **"You! In the red shirt! Call 911!"** ' +
                'People will be more likely and able to help you if they know what you need them to do. ' +
                'Pointing out a person and being clear about what you need is the best way to get others to help you.',
        },
        {
            id: 4,
            question: 'When will HazAdapt support my language?',
            answer:
                'We are working hard to accurately translate our information into as many languages as possible. ' +
                'This will take time, but we would be happy to alert you as soon as your language is available. ' +
                'If there is a particular language(s) you’d like to see, visit our feedback form.',
            buttonTitle: 'Submit Feedback',
            onButtonPress: openSubmitFeedbackFormAsync,
        },
        {
            id: 5,
            question: 'How is my data used?',
            answer:
                'We use your data to improve the overall user experience on the app. ' +
                'We also study ways to improve resilience and hazard adaptation. ' +
                'There is no backdoor into HazAdapt, and we do not and will never sell your personal information to anyone. Ever.',
        },
    ]

    const onSwitchLanguage = async (lang: LanguageCode) => {
        const langObject: NativeLanguageDTO = getLangObjects([lang])[0]
        try {
            await dispatch(switchLanguageThunk({ lang }))
            toast(
                `Preferred language changed to ${langObject.title}.`,
                <IoLanguage color={successColor} size={primaryIconSize} />
            )
        } catch (err) {
            console.error(err)
            toast(
                `Unable to change preferred language to ${langObject.title}.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
    }

    const handleSignup = () => {
        signup()
    }

    const handleLogin = () => {
        login()
    }

    const handleLogout = () => {
        logout()
    }

    const handleProfilePictureChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const MAX_FILE_SIZE = 5120 // 5MB
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        const profilePicture = e.target.files[0]
        if (profilePicture.size / 1024 > MAX_FILE_SIZE) {
            toast(
                'Profile picture must not be larger than 5MB.',
                <IoImageOutline color={errorColor} size={primaryIconSize} />
            )
            return
        }
        dispatch(addProfilePictureThunk(e.target.files[0]))
    }

    const handleProfilePictureDelete = () => {
        dispatch(deleteProfilePictureThunk())
    }

    const handleBoosterPress = async (booster: SupportedContentBooster) => {
        if (user) {
            const preferred_content_boosters = new Set<SupportedContentBooster>(
                user.preferred_content_boosters
            )

            if (!preferred_content_boosters.has(booster)) {
                preferred_content_boosters.add(booster)
            } else {
                preferred_content_boosters.delete(booster)
            }
            dispatch(
                saveProfileChangesThunk({
                    preferred_content_boosters: Array.from(
                        preferred_content_boosters
                    ),
                })
            )
        } else {
            try {
                const storedBoosters = readAsync('preferred_content_boosters')
                let preferred_content_boosters: SupportedContentBooster[] = []

                if (storedBoosters) {
                    preferred_content_boosters = JSON.parse(storedBoosters)
                }

                if (!preferred_content_boosters.includes(booster)) {
                    preferred_content_boosters.push(booster)
                } else {
                    preferred_content_boosters =
                        preferred_content_boosters.filter(
                            (item) => item !== booster
                        )
                }

                storeAsync(
                    'preferred_content_boosters',
                    JSON.stringify(preferred_content_boosters)
                )

                setSelectedBoosters(preferred_content_boosters)
            } catch (error) {
                console.error(
                    'Error occurred while updating preferred content boosters:',
                    error
                )
            }
        }
    }

    const tabs: { [key: string]: TabGroupProps } = {
        General: {
            tabs: [
                {
                    id: 1,
                    label: 'Languages and Customizations',
                    icon: (
                        <img
                            src={
                                activeGeneralTab === 1
                                    ? InfoCustomizationsTeal
                                    : InfoCustomizationsGray
                            }
                            width={mediumWindowOrLarger ? 36 : 28}
                            height="auto"
                            alt="Languages and Customizations tab icon"
                        />
                    ),
                    onPress: () => {
                        navigate('/settings/languages-and-customizations')
                    },
                    scene: props.loading ? (
                        <LoadingView />
                    ) : (
                        <div
                            className={localClasses.languagesAndCustomizations}
                        >
                            <LanguageTab
                                languages={getLangObjects(languages)}
                                selectedLanguage={selectedLanguage}
                                onRadioBtnPress={onSwitchLanguage}
                            />
                            <InformationCustomizationsTab
                                boosters={boosters}
                                onBoosterPress={handleBoosterPress}
                                activeBoosters={selectedBoosters}
                            />
                        </div>
                    ),
                },
                {
                    id: 2,
                    label: 'HazAdapt ID',
                    icon: (
                        <Lock
                            sx={{
                                color:
                                    activeGeneralTab === 2
                                        ? customHazTealColor
                                        : customMidGrayColor,
                            }}
                            fontSize={mediumWindowOrLarger ? 'large' : 'medium'}
                        />
                    ),
                    onPress: () => {
                        navigate('/settings/account')
                    },
                    scene: props.loading ? (
                        <LoadingView />
                    ) : (
                        <AccountTab
                            user={user}
                            handleSignup={handleSignup}
                            handleLogin={handleLogin}
                            handleLogout={handleLogout}
                            handleManage={goToAuthDashboard}
                            profilePictureURI={profilePictureURI}
                            onProfilePictureChange={handleProfilePictureChange}
                            onProfilePictureDelete={handleProfilePictureDelete}
                        />
                    ),
                },
            ],
            value: activeGeneralTab,
        },
        'About Us': {
            tabs: [
                {
                    id: 1,
                    label: 'Privacy Policy',
                    icon: (
                        <Lock
                            sx={{
                                color:
                                    activeAboutUsTab === 1
                                        ? customHazTealColor
                                        : customMidGrayColor,
                            }}
                            fontSize={mediumWindowOrLarger ? 'large' : 'medium'}
                        />
                    ),
                    onPress: openPrivacyPolicyAsync,
                },
                {
                    id: 2,
                    label: 'FAQ',
                    icon: (
                        <Help
                            sx={{
                                color:
                                    activeAboutUsTab === 2
                                        ? customHazTealColor
                                        : customMidGrayColor,
                            }}
                            fontSize={mediumWindowOrLarger ? 'large' : 'medium'}
                        />
                    ),
                    onPress: () => {
                        navigate('/settings/faq')
                    },
                    scene: (
                        <FAQTab
                            blocks={faqs}
                            onReportIssuePress={openReportABugFormAsync}
                            selectedItem={faqs.find(
                                (f) => f.id === selectedFAQ
                            )}
                            onBlockPress={(id: number) => {
                                setSelectedFAQ(id)
                                setFaqBlockPressed(true)
                            }}
                            blockPressed={faqBlockPressed}
                        />
                    ),
                },
                {
                    id: 3,
                    label: 'About',
                    icon: (
                        <Info
                            sx={{
                                color:
                                    activeAboutUsTab === 3
                                        ? customHazTealColor
                                        : customMidGrayColor,
                            }}
                            fontSize={mediumWindowOrLarger ? 'large' : 'medium'}
                        />
                    ),
                    onPress: (id) => {
                        navigate('/settings/about')
                    },
                    scene: <AboutTab />,
                },
            ],
            value: activeAboutUsTab,
        },
        'Tech Support': {
            tabs: [
                {
                    id: 1,
                    label: 'Report Issue',
                    icon: (
                        <BsFillFileEarmarkBarGraphFill
                            color={
                                activeTechSupportTab === 1
                                    ? customHazTealColor
                                    : customMidGrayColor
                            }
                            size={mediumWindowOrLarger ? 30 : 24}
                        />
                    ),
                    onPress: (id) => {
                        navigate('/settings/report-issue')
                    },
                    scene: (
                        <ReportIssueTab
                            onReportIssuePress={openReportABugFormAsync}
                        />
                    ),
                },
            ],
            value: activeTechSupportTab,
        },
    }

    return (
        <SettingsPageTemplate
            data={tabs}
            activeGroup={activeGroup}
            onGroupNamePress={(group) => {
                if (group === 'General') {
                    navigate('/settings/account')
                } else if (group === 'About Us') {
                    navigate('/settings/about')
                } else if (group === 'Tech Support') {
                    navigate('/settings/tech-support')
                }
            }}
            loggedIn={!!user}
        />
    )
}

const useLocalStyles = makeStyles()({
    languagesAndCustomizations: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
    },
})
