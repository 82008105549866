import { Button, Link, Stack } from '@mui/material'
import { breakpoints } from '../styles/universal'
import { Typography } from '@mui/material'
import { IoInformationCircle, IoOpenOutline } from 'react-icons/io5'
import { AnnouncementBanner, Dialog } from '../../components'
import { useState } from 'react'
import { colors } from '@hazadapt-git/public-core-base'
import { titleCaseString } from '../utils'
import { makeStyles } from 'tss-react/mui'

const governmentSourcesUpdate = {
    backgroundColor: '#FFCC46',
    headline: 'Important updates regarding U.S. government sources.',
    content: (
        <Stack gap={'1rem'} maxWidth={breakpoints.md} margin={'0 auto'}>
            <Typography>
                Your safety remains our top priority. Amid recent changes to
                federal emergency safety websites, some U.S. government websites
                and services may not be available.
            </Typography>
            <Typography>
                HazAdapt continues to provide you with essential, evidence-based
                instructions. Our team has spent years researching and
                simplifying information from hundreds of credible,
                scientifically sound, and evidence-based sources, from entities
                like FEMA, the CDC, and the Department of Health and Human
                Services, ensuring our guides are easy to understand and
                accessible offline.
            </Typography>
            <Typography component="span">
                Rest assured, you have reliable safety information at your
                fingertips, whenever and wherever you need it with HazAdapt.{' '}
                <Link
                    href="https://www.hazadapt.com/news/ensuring-reliable-access-to-safety-information-amid-recent-government-changes"
                    target="_blank"
                    rel="noopener noreferrer"
                    display={'inline-flex'}
                >
                    Learn More{' '}
                    <div>
                        <IoOpenOutline />
                        <div
                            style={{
                                backgroundColor: 'blue',
                                height: '1.5px',
                                width: '1rem',
                                marginTop: '-.35rem',
                            }}
                        />
                    </div>
                </Link>
            </Typography>
        </Stack>
    ),
}

export const GovernmentSourcesUpdateBanner = () => (
    <AnnouncementBanner
        backgroundColor={governmentSourcesUpdate.backgroundColor}
        headline={governmentSourcesUpdate.headline}
        content={governmentSourcesUpdate.content}
    />
)

export const GovernmentSourcesUpdateModal = () => {
    const { classes } = useLocalStyles()
    const [open, setOpen] = useState<boolean>(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        <>
            <Button onClick={handleOpen} className={classes.button}>
                <IoInformationCircle size={'1.5rem'} />
                <Typography>{governmentSourcesUpdate.headline}</Typography>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                title={titleCaseString(governmentSourcesUpdate.headline)}
                content={governmentSourcesUpdate.content}
                ctas={[
                    <Button
                        key={'dismiss'}
                        onClick={handleClose}
                        variant="contained"
                    >
                        Dismiss
                    </Button>,
                ]}
            />
        </>
    )
}

const useLocalStyles = makeStyles()({
    button: {
        textDecoration: 'underline',
        display: 'grid',
        gridTemplateColumns: '1.5rem auto',
        gap: '.5rem',
        color: colors.grays.THUNDERCLOUD,
        '&:hover': {
            textDecoration: 'underline',
        },
        textAlign: 'start',
    },
})
