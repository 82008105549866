import {
    CommunitySponsor,
    ContentBlockDTO,
    LanguageCode,
    PrepCheckOverviewDTO,
    PromotedProductDTO,
    searchPromotedProducts,
    SupportedContentBooster,
} from '@hazadapt-git/public-core-base'
import { Grid, Skeleton } from '@mui/material'
import React, { FC, MouseEventHandler, useRef } from 'react'
import { AspectRatio } from 'react-aspect-ratio'
import { ElementContent } from 'react-markdown/lib/ast-to-react'

import CommunitySponsorIcon from '../../assets/icons/CommunitySponsor.svg'
import PromotedProductsIcon from '../../assets/icons/PromotedProducts.svg'
import { ContentBooster } from '../../lib/entities'
import { customBorderRadius } from '../../lib/styles/universal'
import { useWindowSizeExact, useWindowSizeUp } from '../../lib/utils'
import {
    ContentBlockDetails,
    ContentBlockPreview,
    PrepCheckContentBlock,
} from '../molecules'
import { CommunitySponsorBlock } from './CommunitySponsorBlock'
import { PromotedProductsBlock } from './PromotedProductsBlock'

interface ContentBlockViewProps {
    blocks: (ContentBlockDTO | PrepCheckOverviewDTO)[]
    selectedBlockId?: number
    language: LanguageCode
    onBlockPress(id: number): void
    onBlockHover?(id: number): void
    onLinkPress(url: string, block_id: number, creator_id?: number): void
    onSponsorLinkPress(id: number, url: string): void
    boosters: SupportedContentBooster[]
    transformLinkUri?(
        href: string,
        children: ElementContent[],
        title: string | null
    ): string
    loading?: boolean
    hasCommunitySponsors: boolean
    communitySponsors: CommunitySponsor[]
    communitySponsorsOpen: boolean
    onCommunitySponsorsOpen(): void
    communitySponsorBlockId?: number | null
    onSponsorAreaHover?(sponsor_id: number): void
    onSponsorAreaClick?(sponsor_id: number): void
    onSponsorSupplementalLinkPress?(id: number, url: string): void
    onSponsorHelpIconPress?: MouseEventHandler<HTMLDivElement>
    onCommunitySponsorsBlockHover?(): void
    onLocalContentAdditionLinkClick(
        url: string,
        block_id: number,
        creator_id?: number
    ): void
    onLocalContentAdditionHover(block_id: number, creator_id?: number): void
    overrideScroll?: boolean
    onPrepCheckBlockClick(id: number): void
    onPrepCheckShare(id: number): void
    aspectRatio?: number
    hasPromotedProducts: boolean
    promotedProducts: PromotedProductDTO[]
    promotedProductsOpen: boolean
    onPromotedProductsOpen(): void
    onPromotedProductsBlockHover?(): void
    promotedProductBlockId?: number | null
    onPromotedProductAreaHover?(product_id: number): void
    onPromotedProductAreaClick?(product_id: number): void
    onPromotedProductClick(product_id: number, url: string): void
    onPromotedProductHelpIconPress?: MouseEventHandler<HTMLDivElement>
    onPromotedProductBookmarkPress(product_id: number): void
    onPromotedProductShare(product_id: number, url: string): void
}

export const ContentBlockView: FC<ContentBlockViewProps> = (
    props: ContentBlockViewProps
) => {
    const selectedBlock = props.blocks.find(
        (b) => b.id === props.selectedBlockId
    )
    const cbDetailsRef = useRef<HTMLDivElement>(null)
    const cbPreviewRef = useRef<HTMLDivElement>(null)
    const commSponRef = useRef<HTMLDivElement>(null)
    const promotedProductsRef = useRef<HTMLDivElement>(null)
    const [searchQuery, setSearchQuery] = React.useState<string>('')
    const [filteredProducts, setFilteredProducts] = React.useState(
        props.promotedProducts
    )
    const reformattedBoosters = props.boosters.map((b) =>
        b.toString().replace(' ', '_')
    )
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const mediumWindow = useWindowSizeExact('md')
    const aspectRatio = props.aspectRatio || (smallWindowOrLarger ? 0.75 : 2)
    const numSkeletons = mediumWindow ? 8 : 6
    const handleSearchChange = (query: string) => {
        setSearchQuery(query)
        if (query.trim() === '') {
            setFilteredProducts(props.promotedProducts)
        } else {
            const { products: filteredProducts } = searchPromotedProducts(
                props.promotedProducts,
                query
            )
            setFilteredProducts(filteredProducts)
        }
    }

    const scrollToOpenBlock = React.useCallback(() => {
        if (props.overrideScroll || props.loading) return

        setTimeout(() => {
            if (
                props.promotedProductsOpen &&
                promotedProductsRef.current?.scrollIntoView
            ) {
                promotedProductsRef.current.scrollIntoView({
                    behavior: 'smooth',
                })
            } else if (
                props.communitySponsorsOpen &&
                commSponRef.current?.scrollIntoView
            ) {
                commSponRef.current.scrollIntoView({
                    behavior: 'smooth',
                })
            } else if (
                props.selectedBlockId !== undefined &&
                cbDetailsRef.current?.scrollIntoView
            ) {
                cbDetailsRef.current.scrollIntoView({
                    behavior: 'smooth',
                })
            }
        }, 100)
    }, [
        props.communitySponsorsOpen,
        props.overrideScroll,
        props.promotedProductsOpen,
        props.selectedBlockId,
        props.loading,
    ])

    // Smoothly scroll open content block into view
    React.useEffect(() => {
        scrollToOpenBlock()
    }, [scrollToOpenBlock, props.selectedBlockId])

    return (
        <>
            <Grid
                container
                alignItems="center"
                spacing={2}
                paddingBottom="2rem"
            >
                {props.loading ? (
                    Array.from(Array(numSkeletons)).map((_, i) => (
                        <Grid
                            key={`cb_placeholder_${i + 1}`}
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            lg={2}
                        >
                            <AspectRatio
                                ratio={aspectRatio}
                                style={{ width: '100%' }}
                            >
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height="100%"
                                    sx={{ borderRadius: customBorderRadius }}
                                />
                            </AspectRatio>
                        </Grid>
                    ))
                ) : (
                    <>
                        {props.blocks.map(
                            (block: ContentBlockDTO | PrepCheckOverviewDTO) => {
                                if ('scope' in block) {
                                    return (
                                        <Grid
                                            key={`prepCheckCB_${block.id}`}
                                            item
                                            xs={12}
                                            sm={4}
                                            md={3}
                                            lg={2}
                                        >
                                            <PrepCheckContentBlock
                                                {...block}
                                                ref={cbPreviewRef}
                                                onBlockClick={
                                                    props.onPrepCheckBlockClick
                                                }
                                                onShare={props.onPrepCheckShare}
                                            />
                                        </Grid>
                                    )
                                } else {
                                    const reformattedToggles =
                                        block.toggles.map((t) =>
                                            t.toString().replace(' ', '_')
                                        )
                                    return (
                                        <Grid
                                            key={`cb_${block.id}`}
                                            item
                                            xs={12}
                                            sm={4}
                                            md={3}
                                            lg={2}
                                        >
                                            <ContentBlockPreview
                                                ref={cbPreviewRef}
                                                id={block.id}
                                                icon={block.block_icon?.src}
                                                iconAlt={`${block.title} icon`}
                                                title={block.title}
                                                critical={block.critical}
                                                onPress={props.onBlockPress}
                                                onHover={props.onBlockHover}
                                                selected={
                                                    block.id ===
                                                    props.selectedBlockId
                                                }
                                                boosterChips={reformattedBoosters
                                                    .filter((b) =>
                                                        reformattedToggles.includes(
                                                            b
                                                        )
                                                    )
                                                    .map(
                                                        (b) => ContentBooster[b]
                                                    )}
                                                type={block.type}
                                                local={block.local}
                                                creator={block.creator}
                                            />
                                        </Grid>
                                    )
                                }
                            }
                        )}
                        {props.hasPromotedProducts &&
                            props.promotedProductBlockId && (
                                <Grid
                                    key={`cb_promoted_products`}
                                    item
                                    xs={12}
                                    sm={4}
                                    md={3}
                                    lg={2}
                                >
                                    <ContentBlockPreview
                                        ref={cbPreviewRef}
                                        id={props.promotedProductBlockId}
                                        icon={PromotedProductsIcon}
                                        iconAlt={`Promoted products`}
                                        title="Promoted Products"
                                        onPress={props.onPromotedProductsOpen}
                                        selected={
                                            props.selectedBlockId ===
                                            props.promotedProductBlockId
                                        }
                                        type="promoted-products"
                                        aspectRatio={props.aspectRatio}
                                        onHover={
                                            props.onPromotedProductsBlockHover
                                        }
                                    />
                                </Grid>
                            )}
                        {props.hasCommunitySponsors &&
                            props.communitySponsorBlockId && (
                                <Grid
                                    key={`cb_community_sponsors`}
                                    item
                                    xs={12}
                                    sm={4}
                                    md={3}
                                    lg={2}
                                >
                                    <ContentBlockPreview
                                        ref={cbPreviewRef}
                                        id={props.communitySponsorBlockId}
                                        icon={CommunitySponsorIcon}
                                        iconAlt={`Community sponsors icon`}
                                        title="Community Sponsors"
                                        onPress={props.onCommunitySponsorsOpen}
                                        selected={
                                            props.selectedBlockId ===
                                            props.communitySponsorBlockId
                                        }
                                        type="community-sponsors"
                                        aspectRatio={props.aspectRatio}
                                        onHover={
                                            props.onCommunitySponsorsBlockHover
                                        }
                                    />
                                </Grid>
                            )}
                    </>
                )}
            </Grid>
            {props.loading ? null : selectedBlock &&
              !('scope' in selectedBlock) ? (
                <ContentBlockDetails
                    ref={cbDetailsRef}
                    id={selectedBlock.id}
                    title={selectedBlock.title}
                    blockIcon={selectedBlock.block_icon?.src}
                    blockIconAlt={
                        selectedBlock.block_icon?.alt ||
                        `${selectedBlock.title} icon`
                    }
                    image={selectedBlock.image}
                    header={selectedBlock.header}
                    body={selectedBlock.body}
                    critical={selectedBlock.critical}
                    type={selectedBlock.type}
                    onLinkPress={(url) =>
                        props.onLinkPress(
                            url,
                            selectedBlock.id,
                            selectedBlock.creator?.id
                        )
                    }
                    importantSources={selectedBlock.important_sources}
                    onLocalContentAdditionLinkClick={
                        props.onLocalContentAdditionLinkClick
                    }
                    onLocalContentAdditionHover={
                        props.onLocalContentAdditionHover
                    }
                    transformLinkUri={props.transformLinkUri}
                    local={selectedBlock.local}
                    localContent={selectedBlock.local_content}
                    creator={selectedBlock.creator}
                />
            ) : props.hasPromotedProducts &&
              props.selectedBlockId === props.promotedProductBlockId ? (
                <PromotedProductsBlock
                    products={
                        !searchQuery ? props.promotedProducts : filteredProducts
                    }
                    handleSearchChange={handleSearchChange}
                    onSearchQueryChange={setSearchQuery}
                    onLinkPress={props.onPromotedProductClick}
                    ref={promotedProductsRef}
                    onProductAreaHover={props.onPromotedProductAreaHover}
                    onProductAreaClick={props.onPromotedProductAreaClick}
                    onHelpIconPress={props.onPromotedProductHelpIconPress}
                    onBookmarkPress={props.onPromotedProductBookmarkPress}
                    onShare={props.onPromotedProductShare}
                    searchQuery={searchQuery}
                />
            ) : props.hasCommunitySponsors &&
              props.selectedBlockId === props.communitySponsorBlockId ? (
                <CommunitySponsorBlock
                    sponsors={props.communitySponsors}
                    onLinkPress={props.onSponsorLinkPress}
                    ref={commSponRef}
                    onSponsorAreaHover={props.onSponsorAreaHover}
                    onSponsorAreaClick={props.onSponsorAreaClick}
                    onSponsorSupplementalLinkPress={
                        props.onSponsorSupplementalLinkPress
                    }
                    onHelpIconPress={props.onSponsorHelpIconPress}
                />
            ) : null}
        </>
    )
}
