import {
    HazardOverview,
    LanguageCode,
    NativeLanguageDTO,
    SupportedHazardFilter,
    FilterConfig,
    LoggedPage,
    HazardSortingMethod,
} from '@hazadapt-git/public-core-base'
import * as clipboard from 'clipboard-polyfill'
import qs from 'query-string'
import React, { FC, useCallback } from 'react'
import { IoIosLink } from 'react-icons/io'
import {
    IoBookmark,
    IoBookmarkOutline,
    IoCloudDownloadOutline,
    IoLanguage,
} from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom'

import { HazardGuidePageTemplate } from '../components'
import { getEnvVars } from '../lib/config'
import {
    addBookmarkThunk,
    getHazardsThunk,
    removeBookmarkThunk,
    switchLanguageThunk,
    getBookmarksThunk,
} from '../lib/slices'
import { RootState, useAppDispatch, useAppSelector } from '../lib/store'
import {
    successColor,
    primaryIconSize,
    errorColor,
} from '../lib/styles/universal'
import {
    logEvent,
    toast,
    searchHazards,
    getLangObjects,
    onPdfPress,
    loCoLocationDeniedMessage,
    useWindowSizeUp,
    getCurrentLocation,
    loCoLocationFailedMessage,
} from '../lib/utils'
import { PageProps, HazardFilter } from '../lib/entities'
import { readAsync, storeAsync } from '../lib/async-storage'
import { unwrapResult } from '@reduxjs/toolkit'

interface HazardGuidePageProps extends PageProps {}

interface HazardGuideLocationState {
    category?: string
}

const env = getEnvVars()
export const HazardGuidePage: FC<HazardGuidePageProps> = (
    props: HazardGuidePageProps
) => {
    const navigate = useNavigate()
    const location = useLocation()
    const locationState: HazardGuideLocationState =
        location.state as HazardGuideLocationState

    window.history.replaceState({}, document.title) // Clear location.state when page loads

    const params = qs.parse(location.search)

    const dispatch = useAppDispatch()
    const mediumWindowOrLarger = useWindowSizeUp('md')

    const [displayedHazards, setDisplayedHazards] = React.useState<
        HazardOverview[]
    >([])
    const [searchResults, setSearchResults] = React.useState<HazardOverview[]>(
        []
    )

    const [activeCategories, setActiveCategories] = React.useState<string[]>(
        locationState && locationState.category ? [locationState.category] : []
    )
    const [filters, setFilters] = React.useState<FilterConfig[]>([])
    const [activeFilters, setActiveFilters] = React.useState<
        SupportedHazardFilter[]
    >([])
    const [availableFilters, setAvailableFilters] = React.useState<
        SupportedHazardFilter[]
    >([])
    const [searchQuery, setSearchQuery] = React.useState<string>()
    const [suggestedQuery, setSuggestedQuery] = React.useState<string>()
    const [languageSelectorModalOpen, setLanguageSelectorModalOpen] =
        React.useState<boolean>(false)
    const [contentReady, setContentReady] = React.useState<boolean>(false)
    const [locoEnabled, setLocoEnabled] = React.useState<boolean>(false)
    const [
        localContentPopoverTextOverride,
        setLocalContentPopoverTextOverride,
    ] = React.useState<string>()
    const [hazardSortMethod, setHazardSortMethod] =
        React.useState<HazardSortingMethod>('asc')

    const { bookmarkedHazards } = useAppSelector(
        (state: RootState) => state.bookmarks
    )
    const { language, profileReady } = useAppSelector(
        (state: RootState) => state.profile
    )
    const { hazards, languages: supportedLanguages } = useAppSelector(
        (state: RootState) => state.hazards
    )

    const [hazardsRetrieved, setHazardsRetrieved] =
        React.useState<boolean>(false)
    const [bookmarksRetrieved, setBookmarksRetrieved] =
        React.useState<boolean>(false)
    const [filtersExpanded, setFiltersExpanded] = React.useState<boolean>(false)

    const [showNoLocalContentPopover, setShowNoLocalContentPopover] =
        React.useState<boolean>(false)

    React.useEffect(() => {
        document.title = 'Hazard Guide - HazAdapt'

        const loCoOn = readAsync('@loCoOn') === 'true'
        setLocoEnabled(loCoOn)
    }, [])

    React.useEffect(() => {
        const loCoOn = readAsync('@loCoOn') === 'true'
        let position: GeolocationPosition | undefined
        getCurrentLocation(!loCoOn)
            .then((res) => {
                position = res
            })
            .catch(console.error)
            .finally(() => {
                dispatch(getHazardsThunk({ position }))
                    .then(unwrapResult)
                    .then(({ hazards: res }) => {
                        if (loCoOn && !res.some((h) => h.local)) {
                            setShowNoLocalContentPopover(true)
                            storeAsync('@loCoOn', 'false')
                        }
                        if (res.some((h) => h.local))
                            setHazardSortMethod('relevance')
                    })
                    .catch(console.error)
                    .finally(() => {
                        setHazardsRetrieved(true)
                    })
            })
        dispatch(getBookmarksThunk())
            .catch(console.error)
            .finally(() => {
                setBookmarksRetrieved(true)
            })
    }, [dispatch])

    // Log opening the hazard guide
    React.useEffect(() => {
        if (!profileReady) return

        logEvent('OPEN_PAGE', {
            page: LoggedPage.HAZARD_GUIDE,
            language,
        })
    }, [language, profileReady])

    // Save the search query if there is one
    React.useEffect(() => {
        setSearchQuery(
            Array.isArray(params.query)
                ? params.query[0] || ''
                : params.query || ''
        )
    }, [params.query])

    React.useEffect(() => {
        const hazardsToParse = searchQuery ? searchResults : hazards
        const categoryHazards =
            activeCategories.length === 0
                ? [...hazardsToParse]
                : hazardsToParse.filter((h) =>
                      h.types.some((t) =>
                          t === 'Medical'
                              ? activeCategories.includes('Health')
                              : t === 'Common_Incident'
                              ? activeCategories.includes('Common Incidents')
                              : activeCategories.includes(t.toString())
                      )
                  )
        let availableFilters: SupportedHazardFilter[] = []
        for (const h of categoryHazards) {
            availableFilters = Array.from(
                new Set<SupportedHazardFilter>([
                    ...availableFilters,
                    ...h.filters,
                ])
            )
        }
        const shownFilters = Array.from(
            new Set<SupportedHazardFilter>([
                ...availableFilters,
                ...activeFilters,
            ])
        )
        const filterConfig = shownFilters.map((f) => HazardFilter[f])
        setFilters(filterConfig)
        setAvailableFilters(availableFilters)
    }, [hazards, activeCategories, activeFilters, searchQuery, searchResults])

    const refreshPageContent = React.useCallback(
        async (
            hazards: HazardOverview[],
            activeCategories: string[],
            activeFilters: SupportedHazardFilter[],
            searchQuery: string,
            hazardSortMethod: HazardSortingMethod
        ) => {
            // Get search results hazards if there is a search query, otherwise keep all hazards
            const filteredHazards: HazardOverview[] = []
            let hazardsToParse: HazardOverview[]
            let suggestedQuery: string | undefined
            if (searchQuery) {
                const { hazards: results, suggestedQuery: suggestion } =
                    await searchHazards(
                        hazards,
                        searchQuery.trim().toLowerCase()
                    )
                suggestedQuery = suggestion
                hazardsToParse = [...results]
            } else {
                hazardsToParse = [...hazards]
            }

            let visibleFilters: Set<FilterConfig> = new Set<FilterConfig>() // List of filters to show the user

            for (const h of hazardsToParse) {
                // Build the list of filters to show the user
                visibleFilters = new Set<FilterConfig>([
                    ...visibleFilters,
                    ...h.filters.map((f) => HazardFilter[f]),
                ])

                // Filter out irrelevant hazards
                if (
                    activeCategories.length === 0 ||
                    h.types.filter((t) =>
                        t === 'Medical'
                            ? activeCategories.includes('Health')
                            : t === 'Common_Incident'
                            ? activeCategories.includes('Common Incidents')
                            : activeCategories.includes(t.toString())
                    ).length > 0
                ) {
                    if (
                        activeFilters.length === 0 ||
                        h.filters.filter((f) =>
                            activeFilters.includes(
                                f as unknown as SupportedHazardFilter
                            )
                        ).length > 0
                    ) {
                        filteredHazards.push(h)
                    }
                }
            }

            // Sort hazards
            if (activeFilters.length > 0) {
                filteredHazards.sort((a: HazardOverview, b: HazardOverview) => {
                    if (hazardSortMethod === 'asc')
                        return searchQuery ? 0 : a.slug < b.slug ? -1 : 1
                    else if (hazardSortMethod === 'desc')
                        return searchQuery ? 0 : a.slug > b.slug ? -1 : 1

                    const intersectionA: SupportedHazardFilter[] =
                        a.filters.filter((t) => activeFilters.includes(t))
                    const intersectionB: SupportedHazardFilter[] =
                        b.filters.filter((t) => activeFilters.includes(t))
                    if (locoEnabled && a.local) intersectionA.push('Local')
                    if (locoEnabled && b.local) intersectionB.push('Local')
                    if (intersectionA.length !== intersectionB.length) {
                        return intersectionB.length - intersectionA.length
                    } else {
                        for (const toggle of activeFilters) {
                            const toggleInA = intersectionA.includes(toggle)
                            const toggleInB = intersectionB.includes(toggle)
                            if (toggleInA && !toggleInB) {
                                return 1
                            } else if (!toggleInA && toggleInB) {
                                return -1
                            }
                        }
                        if (a.local && !b.local) return -1
                        else if (!a.local && b.local) return 1
                        return searchQuery ? 0 : a.slug < b.slug ? -1 : 1
                    }
                })
            } else {
                filteredHazards.sort((a: HazardOverview, b: HazardOverview) => {
                    if (hazardSortMethod === 'asc')
                        return searchQuery ? 0 : a.slug < b.slug ? -1 : 1
                    else if (hazardSortMethod === 'desc')
                        return searchQuery ? 0 : a.slug > b.slug ? -1 : 1

                    if (a.local && !b.local) return -1
                    else if (!a.local && b.local) return 1
                    return searchQuery ? 0 : a.slug < b.slug ? -1 : 1
                })
            }
            setFilters(Array.from(visibleFilters)) // Save the list of filters to show the user
            setDisplayedHazards(filteredHazards)
            setSearchResults(hazardsToParse)
            setSuggestedQuery(suggestedQuery)
        },
        [locoEnabled]
    )

    React.useEffect(() => {
        if (searchQuery !== undefined) {
            refreshPageContent(
                hazards,
                activeCategories,
                activeFilters,
                searchQuery,
                hazardSortMethod
            )
                .then(() => {
                    if (!contentReady) setContentReady(true)
                })
                .catch(console.error)
        }
    }, [
        hazards,
        activeCategories,
        activeFilters,
        searchQuery,
        contentReady,
        hazardSortMethod,
        refreshPageContent,
    ])

    const onCategoryPress = useCallback(
        (value: string) => {
            const activeChips = [...activeCategories]
            const indx = activeChips.indexOf(value)
            if (indx > -1) activeChips.splice(indx, 1)
            else activeChips.push(value)

            setActiveCategories(activeChips)
        },
        [activeCategories]
    )

    const onFilterPress = useCallback(
        (value: SupportedHazardFilter) => {
            const filter = value
                .toString()
                .replace(' ', '_') as unknown as SupportedHazardFilter
            const filters = [...activeFilters]
            const indx = filters.indexOf(filter)
            if (indx > -1) filters.splice(indx, 1)
            else filters.push(filter)

            setActiveFilters(filters)
            setHazardSortMethod('relevance')
        },
        [activeFilters]
    )

    const onBookmarkPress = useCallback(
        (hazardID: number) =>
            new Promise<void>((resolve) => {
                const books = [...bookmarkedHazards]
                const bookmark = books.find((b) => b.hazard_id === hazardID)
                const hazard = hazards.find((h) => h.id === hazardID)
                if (hazard) {
                    if (bookmark) {
                        dispatch(removeBookmarkThunk(bookmark.bookmark_id))
                            .then(() => {
                                logEvent('REMOVE_BOOKMARK', {
                                    hazard_id: hazardID,
                                })
                                toast(
                                    `Removed ${hazard.name} from your bookmarked hazards.`,
                                    <IoBookmarkOutline
                                        color={successColor}
                                        size={primaryIconSize}
                                    />
                                )
                            })
                            .catch(console.error)
                            .finally(resolve)
                    } else {
                        const hazard = hazards.find((h) => h.id === hazardID)
                        if (hazard) {
                            dispatch(addBookmarkThunk(hazardID))
                                .then(() => {
                                    logEvent('ADD_BOOKMARK', {
                                        hazard_id: hazardID,
                                    })
                                    toast(
                                        `Added ${hazard.name} to your bookmarked hazards.`,
                                        <IoBookmark
                                            color={successColor}
                                            size={primaryIconSize}
                                        />
                                    )
                                })
                                .catch(console.error)
                                .finally(resolve)
                        } else {
                            resolve()
                        }
                    }
                } else {
                    resolve()
                }
            }),
        [bookmarkedHazards, dispatch, hazards]
    )

    const onHazardPress = useCallback(
        (id: number): void => {
            const hazard = hazards.find((h) => h.id === id)
            if (hazard) {
                navigate(`/hazards/${hazard.slug}`)
            }
        },
        [hazards, navigate]
    )

    const onHazardHover = useCallback(
        (id: number): void => {
            const hazard = hazards.find((h) => h.id === id)
            if (!hazard) return

            logEvent('HOVER_HAZARD', {
                hazard_id: hazard.id,
                name: hazard.name,
                creator_id: hazard.creator?.id,
                creator_name: hazard.creator?.name,
                language,
            })
        },
        [hazards, language]
    )

    const onCopyLinkPress = useCallback(
        (id: number): void => {
            const hazard = hazards.find((h) => h.id === id)
            if (hazard) {
                logEvent('SHARE_HAZARD', {
                    hazard_id: hazard.id,
                    name: hazard.name,
                    language,
                })
                const link = `${env.hazGuideQuickUriBase}/${hazard.id}`
                clipboard
                    .writeText(link)
                    .then(() => {
                        toast(
                            'Link copied!',
                            <IoIosLink
                                color={successColor}
                                size={primaryIconSize}
                            />
                        )
                    })
                    .catch(console.error)
            }
        },
        [hazards, language]
    )

    const onFilterReset = useCallback(() => setActiveFilters([]), [])

    const onLanguageChange = useCallback(
        async (language: LanguageCode) => {
            const langObject: NativeLanguageDTO = getLangObjects([language])[0]
            setLanguageSelectorModalOpen(false)
            toast(
                `Downloading hazard information in ${langObject.title}.`,
                <IoCloudDownloadOutline
                    color={successColor}
                    size={primaryIconSize}
                />
            )
            try {
                await dispatch(switchLanguageThunk({ lang: language }))
                let position: GeolocationPosition | undefined
                try {
                    if (locoEnabled) position = await getCurrentLocation()
                } catch (err) {
                    console.error(err)
                }
                await dispatch(getHazardsThunk({ language, position }))
                toast(
                    `Information has been translated into ${langObject.title}.`,
                    <IoLanguage color={successColor} size={primaryIconSize} />
                )
            } catch (err) {
                console.error(err)
                toast(
                    `Unable to download hazard information in ${langObject.title}.`,
                    <IoLanguage color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [dispatch, locoEnabled]
    )

    const onTranslatePress = useCallback(() => {
        setLanguageSelectorModalOpen(true)
    }, [])

    const onLanguageSelectorModalClose = useCallback(() => {
        setLanguageSelectorModalOpen(false)
    }, [])

    const onSuggestionPress = useCallback(() => {
        if (!suggestedQuery) return
        navigate(`/hazards?query=${suggestedQuery}`, {
            replace: true,
        })
    }, [suggestedQuery, navigate])

    const onLocalContentToggle = useCallback(async () => {
        if (!locoEnabled) {
            // LoCo is off; turn it on if location retrieval is successful
            let position: GeolocationPosition | undefined
            try {
                position = await getCurrentLocation()
                setLocalContentPopoverTextOverride(undefined)
            } catch (err: any) {
                setLocalContentPopoverTextOverride(
                    err?.code === 3
                        ? loCoLocationFailedMessage
                        : loCoLocationDeniedMessage
                )
                setShowNoLocalContentPopover(true)
                setLocoEnabled(false)
                return
            }
            // Turn on LoCo in async storage
            storeAsync('@loCoOn', 'true')
            setLocoEnabled(true)
            // Re-pull guide
            dispatch(getHazardsThunk({ language, position }))
                .then(unwrapResult)
                .then(({ hazards: res }) => {
                    if (!res.some((h) => h.local)) {
                        setLocoEnabled(false)
                        setShowNoLocalContentPopover(true)
                    }
                    if (res.some((h) => h.local))
                        setHazardSortMethod('relevance')
                })
                .catch(console.error)
        } else {
            // LoCo is on; turn it off
            storeAsync('@loCoOn', 'false')
            // Re-pull guide
            dispatch(getHazardsThunk({ language }))
                .then(() => {
                    setLocoEnabled(false)
                })
                .catch(console.error)
        }
    }, [dispatch, language, locoEnabled])

    const onCloseNoLocalContentPopover: React.MouseEventHandler =
        useCallback(() => {
            setShowNoLocalContentPopover(false)
            setTimeout(() => {
                setLocalContentPopoverTextOverride(undefined)
            }, 750)
        }, [])

    const scrollY = React.useRef(0)
    React.useEffect(() => {
        if (mediumWindowOrLarger) {
            if (filtersExpanded) setFiltersExpanded(false)
            return
        }
        const closeFilterViewOnScrollDown = () => {
            if (!filtersExpanded || !mediumWindowOrLarger)
                if (scrollY.current < window.scrollY) {
                    setFiltersExpanded(false)
                }
            scrollY.current = window.scrollY
        }

        window.addEventListener('scroll', closeFilterViewOnScrollDown)
        return () => {
            window.removeEventListener('scroll', closeFilterViewOnScrollDown)
        }
    }, [filtersExpanded, mediumWindowOrLarger])

    return (
        <HazardGuidePageTemplate
            hazardGuideData={displayedHazards.map((hazard) => ({
                id: hazard.id,
                slug: hazard.slug,
                icon: hazard.icon.src,
                name: hazard.name,
                description: hazard.description,
                local: hazard.local,
                creator: hazard.creator,
                filterChips: activeFilters
                    .filter((f) => hazard.filters.includes(f))
                    .map((f) => HazardFilter[f]),
                bookmarked: bookmarkedHazards.some(
                    (b) => b.hazard_id === hazard.id
                ),
            }))}
            onHazardPress={onHazardPress}
            onHazardHover={onHazardHover}
            onCopyLinkPress={onCopyLinkPress}
            onBookmark={onBookmarkPress}
            onPdfPress={(id, slug) => onPdfPress(id, slug, language)}
            activeCategories={activeCategories}
            onCategoryPress={onCategoryPress}
            filters={filters}
            activeFilters={activeFilters}
            availableFilters={availableFilters}
            onChipPress={onFilterPress}
            onChipReset={onFilterReset}
            noSearchResults={!props.loading && searchResults.length === 0}
            languages={supportedLanguages}
            selectedLanguage={language}
            onLanguageChange={onLanguageChange}
            onLanguageSelectorModalClose={onLanguageSelectorModalClose}
            languageSelectorModalOpen={languageSelectorModalOpen}
            onTranslatePress={onTranslatePress}
            onLocalContentToggle={onLocalContentToggle}
            loading={
                props.loading ||
                !hazardsRetrieved ||
                !bookmarksRetrieved ||
                !contentReady
            }
            searchQuery={searchQuery}
            locoEnabled={locoEnabled}
            suggestedQuery={suggestedQuery}
            onSuggestionPress={onSuggestionPress}
            showNoLocalContentPopover={showNoLocalContentPopover}
            onCloseNoLocalContentPopover={onCloseNoLocalContentPopover}
            localContentPopoverTextOverride={localContentPopoverTextOverride}
            sortMethod={hazardSortMethod}
            onSortingMethodChange={setHazardSortMethod}
            filtersExpanded={filtersExpanded}
            onFilterViewToggle={setFiltersExpanded}
        />
    )
}
