import {
    HazardMultiLangDTO,
    ContentBlockDTO,
    ContentBlockMultiLangDTO,
    SupportedContentBooster,
    LanguageCode,
    SupportedHazardStage,
    NativeLanguageDTO,
    BoosterConfig,
    CommunitySponsor,
    LoggedPage,
    PrepCheckOverviewDTO,
    PromotedProductDTO,
    UserFacingHazardStage,
    User,
    Tutorial,
} from '@hazadapt-git/public-core-base'
import { Error } from '@mui/icons-material'
import * as clipboard from 'clipboard-polyfill'
import React, { FC } from 'react'
import { IoIosLink } from 'react-icons/io'
import {
    IoLanguage,
    IoBookmark,
    IoBookmarkOutline,
    IoCloudDownloadOutline,
    IoMap,
    IoAlertCircle,
} from 'react-icons/io5'
import { useNavigate, useLocation } from 'react-router-dom'

import { IndvHazardPageTemplate } from '../components'
import { getEnvVars } from '../lib/config'
import { PageProps, ContentBooster } from '../lib/entities'
import {
    addBookmarkThunk,
    logHazardViewEventThunk,
    logContentBlockViewEventThunk,
    removeBookmarkThunk,
    switchLanguageThunk,
    getBookmarksThunk,
    saveProfileChangesThunk,
    getHazardsThunk,
} from '../lib/slices'
import { useAppDispatch, useAppSelector, RootState } from '../lib/store'
import {
    successColor,
    primaryIconSize,
    errorColor,
} from '../lib/styles/universal'
import {
    logEvent,
    getLangObjects,
    toast,
    getCommunitySponsors,
    onPdfPress,
    transformLinkUri,
    getHazard,
    loCoLocationDeniedMessage,
    getPrepChecksByHazard,
    useWindowSizeUp,
    getPromotedProducts,
    deleteProductBookmark,
    addProductBookmark,
    login,
    backendStageMap,
    getCurrentLocation,
    loCoLocationFailedMessage,
    userFacingStageMap,
} from '../lib/utils'
import { readAsync, storeAsync } from '../lib/async-storage'
import { unwrapResult } from '@reduxjs/toolkit'
import voca from 'voca'

interface HazardPageProps extends PageProps {}

const { hazGuideQuickUriBase, frontendUriBase } = getEnvVars()
export const HazardPage: FC<HazardPageProps> = (props: HazardPageProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const mediumWindowOrLarger = useWindowSizeUp('md')

    const dispatch = useAppDispatch()

    const modifiedBoosters = React.useRef<boolean>(false)

    const [hazard, setHazard] = React.useState<HazardMultiLangDTO>()
    const [bookmarkID, setBookmarkID] = React.useState<number>()
    const [hazardRetrieved, setHazardRetrieved] = React.useState<boolean>()

    const { bookmarkedHazards } = useAppSelector(
        (state: RootState) => state.bookmarks
    )
    const { language, profileReady, user } = useAppSelector(
        (state: RootState) => state.profile
    )

    const [activeBlocks, setActiveBlocks] = React.useState<
        (ContentBlockDTO | PrepCheckOverviewDTO)[]
    >([])
    const [boosterStack, setBoosterStack] = React.useState<
        SupportedContentBooster[]
    >([])
    const [blocksReady, setBlocksReady] = React.useState<boolean>(false)

    const [activeStage, setActiveStage] = React.useState<SupportedHazardStage>()
    const [userFacingActiveStage, setUserFacingActiveStage] =
        React.useState<UserFacingHazardStage>()

    const [openPrepareBlock, setOpenPrepareBlock] = React.useState<
        number | undefined
    >()
    const [openReactBlock, setOpenReactBlock] = React.useState<
        number | undefined
    >()
    const [openRecoverBlock, setOpenRecoverBlock] = React.useState<
        number | undefined
    >()

    const [bookmarksRetrieved, setBookmarksRetrieved] =
        React.useState<boolean>(false)

    const [languageSelectorModalOpen, setLanguageSelectorModalOpen] =
        React.useState<boolean>(false)

    const [locoEnabled, setLocoEnabled] = React.useState<boolean>(false)
    const [boostersExpanded, setBoostersExpanded] =
        React.useState<boolean>(false)

    const [boosters, setBoosters] = React.useState<BoosterConfig[]>([])
    const [availableBoosters, setAvailableBoosters] = React.useState<
        SupportedContentBooster[]
    >([])

    const [communitySponsors, setCommunitySponsors] = React.useState<
        CommunitySponsor[]
    >([])
    const [communitySponsorsOpen, setCommunitySponsorsOpen] =
        React.useState<SupportedHazardStage | null>(null)

    const [promotedPrepProducts, setPromotedPrepProducts] = React.useState<
        PromotedProductDTO[]
    >([])
    const [promotedRecoveryProducts, setPromotedRecoveryProducts] =
        React.useState<PromotedProductDTO[]>([])
    const [promotedProductsOpen, setPromotedProductsOpen] =
        React.useState<SupportedHazardStage | null>(null)
    const [showPromotedProductsLoginWall, setShowPromotedProductsLoginWall] =
        React.useState<boolean>(false)

    const [showNoLocalContentPopover, setShowNoLocalContentPopover] =
        React.useState<boolean>(false)
    const [
        localContentPopoverTextOverride,
        setLocalContentPopoverTextOverride,
    ] = React.useState<string>()

    const [prepChecks, setPrepChecks] = React.useState<PrepCheckOverviewDTO[]>(
        []
    )
    const boosterStackRef = React.useRef(boosterStack)

    React.useEffect(() => {
        dispatch(getBookmarksThunk()).finally(() => {
            setBookmarksRetrieved(true)
        })
    }, [dispatch, user])

    React.useEffect(() => {
        if (!hazard) return
        logEvent('OPEN_PAGE', {
            page: LoggedPage.HAZARD,
            language,
        }) // Log a page visit for analytics
        logEvent('VIEW_HAZARD', {
            hazard_id: hazard.id,
            name: hazard.name[LanguageCode.ENGLISH],
            creator_id: hazard.creator?.id,
            creator_name: hazard.creator?.name,
            language:
                hazard.translations.includes(language) && hazard.name[language]
                    ? language
                    : LanguageCode.ENGLISH,
        }) // Log a hazard visit for analytics
        logEvent('SELECT_HAZARD_STAGE', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            stage: hazard.default_stage,
            language,
        }) // Log a hazard stage visit for analytics
        // NOTE: Only log when the hazard is first loaded (or the ID changes), OR when the language changes,
        // not every time the hazard object changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hazard?.id, language])

    React.useEffect(() => {
        if (!profileReady) return

        const shouldDisableAutoShowNoLoco = (): boolean => {
            if (user) {
                return user.tutorials_shown.includes(
                    Tutorial.DISABLE_AUTO_SHOW_NO_LOCO
                )
            } else {
                return readAsync(Tutorial.DISABLE_AUTO_SHOW_NO_LOCO) === 'true'
            }
        }

        const getHazardContentFirstPull = (point?: [number, number]) => {
            const urlPieces = location.pathname.split('/')
            const identifier = urlPieces[urlPieces.length - 1]
            getHazard(identifier, language, point)
                .then((res) => {
                    const hazardToShow = { ...res }
                    const hasLocalContent = hazardToShow.content_blocks.some(
                        (cb) => cb.local || cb.local_content.length > 0
                    )
                    if (
                        point &&
                        hazardToShow.local &&
                        !hazardToShow.creator &&
                        hasLocalContent
                    ) {
                        for (const cb of hazardToShow.content_blocks) {
                            if (!cb.local) continue
                            const boosters: Set<SupportedContentBooster> =
                                new Set<SupportedContentBooster>(cb.toggles)
                            boosters.add('Local')
                            cb.toggles = [...Array.from(boosters)]
                        }
                        setBoosterStack(['Local'])
                    }
                    setHazard(hazardToShow)

                    // Set the page title
                    document.title = `${res.name[language]} - HazAdapt`
                    if (point) {
                        if (hasLocalContent) {
                            setLocoEnabled(true)
                        } else {
                            setLocoEnabled(false)
                            const disableAutoShowNoLoco =
                                shouldDisableAutoShowNoLoco()
                            if (!disableAutoShowNoLoco) {
                                setShowNoLocalContentPopover(true)
                                setLocalContentPopoverTextOverride(undefined)
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.error(err)
                    navigate('/hazards')
                })
                .finally(() => {
                    setHazardRetrieved(true)
                })
        }

        const getContentBasedOnPosition = async () => {
            try {
                const position = await getCurrentLocation()
                const { latitude, longitude } = position.coords
                getHazardContentFirstPull([latitude, longitude])
            } catch (err) {
                console.error(err)
                setLocoEnabled(false)
                getHazardContentFirstPull()
            }
        }

        const loCoDefaultOn = readAsync('@loCoOn') === 'true'
        if (loCoDefaultOn) {
            if (navigator.permissions) {
                navigator.permissions
                    .query({ name: 'geolocation' })
                    .then(({ state }) => {
                        if (state !== 'denied') {
                            getContentBasedOnPosition()
                        } else {
                            storeAsync('@loCoOn', 'false')
                            setLocoEnabled(false)
                            getHazardContentFirstPull()
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                        storeAsync('@loCoOn', 'false')
                        setLocoEnabled(false)
                        getHazardContentFirstPull()
                    })
            } else {
                getContentBasedOnPosition()
            }
        } else {
            getHazardContentFirstPull()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, profileReady, navigate, user])

    React.useEffect(() => {
        if (!hazard) return

        const bookmark = bookmarkedHazards.find(
            (b) => b.hazard_id === hazard.id
        )
        setBookmarkID(bookmark?.bookmark_id)
    }, [hazard, bookmarkedHazards])

    React.useEffect(() => {
        if (!hazard) return
        getPrepChecksByHazard(hazard.id, language)
            .then(setPrepChecks)
            .catch(console.error)
    }, [hazard, language])

    const onCommunitySponsorsOpen = React.useCallback(
        async (userFacingActiveStage: UserFacingHazardStage) => {
            if (
                !hazard ||
                !hazard.has_sponsors ||
                !('geolocation' in navigator)
            )
                return
            logEvent('OPEN_COMMUNITY_SPONSORS', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                language,
            }) // Log the user opening the community sponsors block
            try {
                const sponsors = await getCommunitySponsors(hazard.id)
                setCommunitySponsors(sponsors)
                setCommunitySponsorsOpen(
                    backendStageMap.get(userFacingActiveStage) ?? 'During'
                )
            } catch (err) {
                console.error(err)
                toast(
                    'Unable to get community sponsors. Please check your internet connection and try again.',
                    <Error
                        htmlColor={errorColor}
                        sx={{ fontSize: primaryIconSize }}
                    />
                )
                return
            }
        },
        [hazard, language]
    )

    const onPromotedProductsOpen = React.useCallback(
        async (
            userFacingActiveStage: UserFacingHazardStage,
            user?: User | null
        ) => {
            if (
                !hazard ||
                !hazard.promoted_products_block_id ||
                !userFacingActiveStage ||
                user === undefined
            )
                return
            if (userFacingActiveStage === 'React') return
            if (
                (userFacingActiveStage === 'Prepare' &&
                    !hazard.has_promoted_prep_products) ||
                (userFacingActiveStage === 'Recover' &&
                    !hazard.has_promoted_recovery_products)
            )
                return
            logEvent('OPEN_PROMOTED_PRODUCTS', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                language,
                stage: userFacingActiveStage,
            }) // Log the user opening the community sponsors block
            try {
                const products = await getPromotedProducts(
                    hazard.id,
                    userFacingActiveStage,
                    !!user
                )
                if (userFacingActiveStage === 'Prepare')
                    setPromotedPrepProducts(products)
                else setPromotedRecoveryProducts(products)
                setPromotedProductsOpen(
                    backendStageMap.get(userFacingActiveStage) ?? null
                )
            } catch (err) {
                console.error(err)
                toast(
                    `Unable to get promoted ${
                        userFacingActiveStage === 'Prepare'
                            ? 'emergency'
                            : 'recovery'
                    } products. Please check your internet connection and try again.`,
                    <Error
                        htmlColor={errorColor}
                        sx={{ fontSize: primaryIconSize }}
                    />
                )
                return
            }
        },
        [hazard, language]
    )

    React.useEffect(() => {
        if (!hazard || user === undefined) return

        if (activeStage) {
            setUserFacingActiveStage(userFacingStageMap[activeStage])
        } else {
            const searchParams = new URLSearchParams(window.location.search)
            const urlStage = searchParams.get('stage')
            const openBlockStr = searchParams.get('open_block')
            let formattedUrlStage = urlStage
                ? voca.titleCase(urlStage)
                : hazard.default_stage
            const stages = Array.from(backendStageMap.keys())
            if (['Before', 'During', 'After'].includes(formattedUrlStage)) {
                setActiveStage(formattedUrlStage as SupportedHazardStage)
                formattedUrlStage =
                    stages.find(
                        (k) =>
                            backendStageMap.get(k as UserFacingHazardStage) ===
                            formattedUrlStage
                    ) ?? hazard.default_stage
            } else if (stages.includes(formattedUrlStage)) {
                setActiveStage(
                    backendStageMap.get(
                        formattedUrlStage as UserFacingHazardStage
                    )
                )
            }
            if (!openBlockStr) return
            const openBlock = parseInt(openBlockStr, 10)

            switch (formattedUrlStage) {
                case 'Prepare': {
                    if (openBlock === hazard.promoted_products_block_id)
                        // Promoted Products is open
                        onPromotedProductsOpen('Prepare', user)
                    else if (openBlock === hazard.community_sponsors_block_id)
                        // Community Sponsors is open
                        onCommunitySponsorsOpen(formattedUrlStage)
                    setOpenPrepareBlock(openBlock)
                    break
                }
                case 'React': {
                    if (openBlock === hazard.community_sponsors_block_id)
                        // Community Sponsors is open
                        onCommunitySponsorsOpen(formattedUrlStage)
                    setOpenReactBlock(openBlock)
                    break
                }
                case 'Recover': {
                    if (openBlock === hazard.promoted_products_block_id)
                        // Promoted Products is open
                        onPromotedProductsOpen('Recover', user)
                    else if (openBlock === hazard.community_sponsors_block_id)
                        // Community Sponsors is open
                        onCommunitySponsorsOpen(formattedUrlStage)
                    setOpenRecoverBlock(openBlock)
                    break
                }
                default: {
                    break
                }
            }
        }
    }, [
        hazard,
        activeStage,
        onPromotedProductsOpen,
        onCommunitySponsorsOpen,
        user,
    ])

    React.useEffect(() => {
        // Build list of boosters to show
        if (!hazard || !activeStage || !userFacingActiveStage) return

        const boosterList = [...boosterStack]
        Object.keys(hazard.toggle_availability).forEach((t) => {
            if (
                hazard.toggle_availability[t][userFacingActiveStage] &&
                !boosterList.includes(t as unknown as SupportedContentBooster)
            ) {
                boosterList.push(t as unknown as SupportedContentBooster)
            }
        })
        const boosterConfigs: BoosterConfig[] = boosterList
            .map((b) => ContentBooster[b])
            .filter((b) => b !== undefined)

        setBoosters(boosterConfigs)
    }, [activeBlocks, activeStage, userFacingActiveStage, boosterStack, hazard])

    React.useEffect(() => {
        if (!hazard) return

        let availableBoosters: SupportedContentBooster[] = []
        for (const block of hazard.content_blocks) {
            if (block.stage === activeStage && block.type === 'content') {
                availableBoosters = Array.from(
                    new Set<SupportedContentBooster>([
                        ...availableBoosters,
                        ...block.toggles,
                    ])
                )
            }
        }
        setAvailableBoosters(availableBoosters)
    }, [activeStage, hazard])

    React.useEffect(() => {
        if (modifiedBoosters.current) return

        if (user) {
            const preferredBoosters = user.preferred_content_boosters.filter(
                (booster) => availableBoosters.includes(booster)
            )
            const boosters = Array.from(
                new Set([...preferredBoosters, ...boosterStackRef.current])
            )
            if (boosters.includes('Local')) {
                const idx = boosters.findIndex((s) => s === 'Local')
                boosters.splice(idx, 1)
                boosters.push('Local')
            }
            setBoosterStack(boosters)
        } else {
            const storedBoosters = readAsync('preferred_content_boosters')
            if (storedBoosters) {
                let preferred_content_boosters: SupportedContentBooster[] = []
                preferred_content_boosters = JSON.parse(storedBoosters)
                const preferredBoosters = preferred_content_boosters.filter(
                    (booster) => availableBoosters.includes(booster)
                )
                const boosters = Array.from(
                    new Set([...preferredBoosters, ...boosterStackRef.current])
                )
                if (boosters.includes('Local')) {
                    const idx = boosters.findIndex((s) => s === 'Local')
                    boosters.splice(idx, 1)
                    boosters.push('Local')
                }
                setBoosterStack(boosters)
            }
        }
    }, [availableBoosters, user])

    const [viewLogged, setViewLogged] = React.useState<boolean>(false)
    React.useEffect(() => {
        if (!profileReady || !hazard || viewLogged) return

        if (
            !hazard.translations.includes(language) ||
            !hazard.name ||
            !(language in hazard.name)
        ) {
            // If hazard.translations does not include the user's preferred language, alert the user with a toast
            const langObject: NativeLanguageDTO = getLangObjects([language])[0]
            toast(
                `${hazard.name[LanguageCode.ENGLISH]} is not available in ${
                    langObject.title
                }. Defaulting to English.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
        dispatch(logHazardViewEventThunk(hazard.id))
            .then(() => {
                setViewLogged(true)
            })
            .catch(console.error)
    }, [dispatch, hazard, language, viewLogged, profileReady])

    React.useEffect(() => {
        if (hazard) {
            if (activeStage === 'Before') {
                openPrepareBlock &&
                    dispatch(
                        logContentBlockViewEventThunk({
                            hazard_id: hazard.id,
                            content_block_id: openPrepareBlock,
                        })
                    )
            } else if (activeStage === 'During') {
                openReactBlock &&
                    dispatch(
                        logContentBlockViewEventThunk({
                            hazard_id: hazard.id,
                            content_block_id: openReactBlock,
                        })
                    )
            } else {
                openRecoverBlock &&
                    dispatch(
                        logContentBlockViewEventThunk({
                            hazard_id: hazard.id,
                            content_block_id: openRecoverBlock,
                        })
                    )
            }
        }
    }, [
        openPrepareBlock,
        openReactBlock,
        openRecoverBlock,
        activeStage,
        hazard,
        dispatch,
    ])

    React.useEffect(() => {
        /**
         * Content-Block Priority:
         *
         * 1. toggle-only IEA
         * 2. toggle-inclusive IEA
         * 3. toggle-only blocks & toggle-inclusive blocks (sort by priority)
         * 4. non-inclusive IEA
         * 5. non-inclusive blocks
         */

        if (hazard) {
            boosterStackRef.current = [...boosterStack]

            const blocks: ContentBlockMultiLangDTO[] =
                hazard.content_blocks.filter(
                    (e: ContentBlockMultiLangDTO) =>
                        e.stage === activeStage && // Stage matches
                        (e.title[LanguageCode.ENGLISH] !== 'Sources' ||
                            (e.title[LanguageCode.ENGLISH] === 'Sources' &&
                                e.body[LanguageCode.ENGLISH].length > 0)) // Block is either a content block or a non-empty sources block
                )
            // Filter blocks to show blocks that fall under the currently enabled toggles
            const reformattedBoosterStack = boosterStack.map((b) =>
                b.toString().replace(' ', '_')
            )
            const filteredBlocks = blocks.filter(
                (cb: ContentBlockMultiLangDTO) => {
                    const res = cb.toggles.filter(
                        (t) =>
                            reformattedBoosterStack.includes(t) ||
                            t === 'Adults'
                    )
                    return res.length > 0
                }
            )

            // Reorder blocks so that most recent toggles' blocks show up first
            const orderedBlocks: Set<ContentBlockDTO> =
                new Set<ContentBlockDTO>()

            /**
             * Content Block Sort Order:
             * 1. IEA with most boosters
             * 2. IEA with booster
             * 3. IEA
             * 4. Content with most booster
             * 5. Content with most recent booster
             * 6. Content with booster
             * 7. content
             */

            const sortBlocks = (arr: ContentBlockMultiLangDTO[]) => {
                arr.sort(
                    (
                        a: ContentBlockMultiLangDTO,
                        b: ContentBlockMultiLangDTO
                    ) => {
                        if (a.title[LanguageCode.ENGLISH] === 'Sources')
                            return 1
                        if (b.title[LanguageCode.ENGLISH] === 'Sources')
                            return -1

                        // determine how many boosters are toggled on content
                        const intersectionA: SupportedContentBooster[] =
                            a.toggles.filter((t) => boosterStack.includes(t))
                        const intersectionB: SupportedContentBooster[] =
                            b.toggles.filter((t) => boosterStack.includes(t))

                        // sort by IEA status first
                        if (a.critical && !b.critical) return -1
                        else if (!a.critical && b.critical) return 1
                        // content is both critical
                        else if (a.critical && b.critical) {
                            // sort by most boosters
                            if (intersectionA.length !== intersectionB.length) {
                                return (
                                    intersectionB.length - intersectionA.length
                                )
                            } else {
                                // Sort by recency of picking the tag
                                for (const toggle of boosterStack) {
                                    const toggleInA =
                                        intersectionA.includes(toggle)
                                    const toggleInB =
                                        intersectionB.includes(toggle)
                                    if (toggleInA && !toggleInB) {
                                        return 1
                                    } else if (!toggleInA && toggleInB) {
                                        return -1
                                    }
                                }

                                // Sort by priority
                                return a.priority - b.priority
                            }
                        }
                        // neither block is critical
                        else {
                            // sort by most boosters
                            if (intersectionA.length !== intersectionB.length) {
                                return (
                                    intersectionB.length - intersectionA.length
                                )
                            } else if (
                                intersectionA.length > 0 &&
                                intersectionB.length > 0
                            ) {
                                for (const toggle of boosterStack) {
                                    const toggleInA =
                                        intersectionA.includes(toggle)
                                    const toggleInB =
                                        intersectionB.includes(toggle)
                                    if (toggleInA && !toggleInB) {
                                        return 1
                                    } else if (!toggleInA && toggleInB) {
                                        return -1
                                    }
                                }
                            } else {
                                if (intersectionA.length > 0) return 1
                                else if (intersectionB.length > 0) return -1
                            }

                            return a.priority - b.priority
                        }
                    }
                )
                return arr
            }

            sortBlocks(filteredBlocks).forEach((cb) => {
                orderedBlocks.add({
                    ...cb,
                    critical: !!cb.critical,
                    title: cb.title[language] ?? cb.title[LanguageCode.ENGLISH],
                    header:
                        cb.header[language] ?? cb.header[LanguageCode.ENGLISH],
                    body: cb.body[language] ?? cb.body[LanguageCode.ENGLISH],
                    image: cb.image
                        ? {
                              ...cb.image,
                              alt:
                                  cb.image.alt[language] ??
                                  cb.image.alt[LanguageCode.ENGLISH] ??
                                  '',
                          }
                        : undefined,
                    block_icon: cb.block_icon
                        ? {
                              ...cb.block_icon,
                              alt:
                                  cb.block_icon.alt[language] ??
                                  cb.block_icon.alt[LanguageCode.ENGLISH] ??
                                  '',
                          }
                        : undefined,
                    local_content:
                        cb.local_content?.map((lc) => ({
                            ...lc,
                            body:
                                lc.body[language] ??
                                lc.body[LanguageCode.ENGLISH],
                        })) ?? [],
                })
            })

            const orderedBlockList: (ContentBlockDTO | PrepCheckOverviewDTO)[] =
                Array.from(orderedBlocks)
            const firstBlockWithoutBoosters = orderedBlockList.findIndex(
                (b) => {
                    if ('scope' in b) return false
                    const overlap = b.toggles.filter((t) =>
                        boosterStack.includes(t)
                    )
                    return overlap.length === 0
                }
            )
            if (activeStage === 'Before') {
                orderedBlockList.splice(
                    firstBlockWithoutBoosters,
                    0,
                    ...prepChecks
                )
            }

            setActiveBlocks(orderedBlockList)
            setBlocksReady(true)
        }
    }, [hazard, boosterStack, activeStage, language, prepChecks])

    /**
     * Handle a pressed link
     * @param url the URL the user pressed on
     * @returns boolean (required but we ignore it)
     */
    const onLinkPress = async (
        url: string,
        content_block_id: number,
        creator_id?: number
    ) => {
        if (hazard) {
            const cb = hazard.content_blocks.find(
                (c) => c.id === content_block_id
            )
            logEvent('CLICK_HAZARD_CONTENT_LINK', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                content_block_id,
                content_block_title: cb?.title[LanguageCode.ENGLISH],
                creator_id,
                creator_name: cb?.creator?.name,
                language,
                url,
            })
        }
        if (url) {
            if (url.startsWith('/')) {
                navigate(url) // Go to the in-app link
            } else {
                window.open(url, '_blank', 'noopener') // Open the link in a new tab
            }
        }
    }

    const onStagePress = (stage: SupportedHazardStage) => {
        if (!hazard) return

        logEvent('SELECT_HAZARD_STAGE', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            stage: userFacingStageMap[stage],
            language,
        })
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set('stage', stage.toLowerCase())

        setActiveStage(stage)
        if (stage === 'Before') {
            setUserFacingActiveStage('Prepare')
            if (openPrepareBlock)
                searchParams.set('open_block', openPrepareBlock.toString())
        } else if (stage === 'During') {
            setUserFacingActiveStage('React')
            if (openReactBlock)
                searchParams.set('open_block', openReactBlock.toString())
        } else {
            setUserFacingActiveStage('Recover')
            if (openRecoverBlock)
                searchParams.set('open_block', openRecoverBlock.toString())
        }

        window.history.pushState(
            {},
            '',
            `${location.pathname}?${searchParams.toString()}`
        )
    }

    const updateUrlParamsWithOpenBlockId = (id: number): void => {
        const searchParams = new URLSearchParams(window.location.search)
        if (userFacingActiveStage === 'Prepare') {
            searchParams.set('open_block', id.toString())
        } else if (userFacingActiveStage === 'React') {
            searchParams.set('open_block', id.toString())
        } else if (userFacingActiveStage === 'Recover') {
            searchParams.set('open_block', id.toString())
        }
        window.history.pushState(
            {},
            '',
            `${location.pathname}?${searchParams.toString()}`
        )
    }

    /**
     * Handle a pressed block
     * @param id hazard ID
     */
    const onBlockPress = (id: number) => {
        if (!hazard) return
        const cb: ContentBlockMultiLangDTO | undefined =
            hazard.content_blocks.find((c) => c.id === id)
        if (!cb) return

        const selectedBlock =
            activeStage === 'Before'
                ? openPrepareBlock
                : activeStage === 'During'
                ? openReactBlock
                : openRecoverBlock
        if (selectedBlock !== cb.id) {
            // If the block was collapsed
            logEvent('VIEW_CONTENT_BLOCK', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                content_block_id: id,
                content_block_title: cb.title[LanguageCode.ENGLISH],
                creator_id: cb.creator?.id,
                creator_name: cb.creator?.name,
                language,
            }) // Log a hazard visit for analytics
        }

        // Update which block is open
        if (activeStage === 'Before') {
            setOpenPrepareBlock(id)
        } else if (activeStage === 'During') {
            setOpenReactBlock(id)
        } else {
            setOpenRecoverBlock(id)
        }

        updateUrlParamsWithOpenBlockId(id)
    }

    const onBlockHover = (id: number): void => {
        if (!hazard) return
        const cb = hazard.content_blocks.find((c) => c.id === id)
        if (!cb) return

        logEvent('HOVER_CONTENT_BLOCK', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            content_block_id: id,
            content_block_title: cb.title[LanguageCode.ENGLISH],
            creator_id: cb.creator?.id,
            creator_name: cb.creator?.name,
            language,
        })
    }

    const onCommunitySponsorsBlockHover = (): void => {
        if (!hazard) return

        logEvent('HOVER_COMMUNITY_SPONSORS_BLOCK', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            stage: userFacingActiveStage,
            language,
        })
    }

    const onPromotedProductsBlockHover = (): void => {
        if (!hazard) return

        logEvent('HOVER_PROMOTED_PRODUCTS_BLOCK', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            stage: userFacingActiveStage,
            language,
        })
    }

    /**
     * Handle a pressed booster
     * @param booster the pressed booster
     */
    const onBoosterPress = async (booster: SupportedContentBooster) => {
        if (!hazard || !userFacingActiveStage) return
        const formattedBooster = booster.replace(' ', '_')
        if (
            booster !== 'Local' &&
            !hazard.toggle_availability[formattedBooster][userFacingActiveStage]
        ) {
            // Not LoCo, and booster is not available in this hazard stage
            return
        }

        const stack: SupportedContentBooster[] = [...boosterStack]
        // If the toggle was on, remove it from the stack of "on" toggles
        const idx: number = stack.findIndex((tog) => tog === booster)
        if (idx !== -1) {
            stack.splice(idx, 1)
        } else {
            stack.push(booster)
        }
        modifiedBoosters.current = true
        setBoosterStack(stack)
    }

    const onBoosterReset = () => setBoosterStack([])

    const onLanguageChange = async (lang: LanguageCode) => {
        if (!hazard) return
        setLanguageSelectorModalOpen(false)
        const langObject: NativeLanguageDTO = getLangObjects([lang])[0]
        toast(
            `Downloading hazard information in ${langObject.title}.`,
            <IoCloudDownloadOutline
                color={successColor}
                size={primaryIconSize}
            />
        )
        try {
            await dispatch(
                switchLanguageThunk({
                    lang,
                    params: {
                        hazard_id: hazard.id,
                        hazard_name: hazard.name[LanguageCode.ENGLISH],
                    },
                })
            )
            const urlPieces = location.pathname.split('/')
            const identifier = urlPieces[urlPieces.length - 1]
            const res = await getHazard(identifier, lang)
            setHazard(res)
            toast(
                `Information has been translated into ${langObject.title}.`,
                <IoLanguage color={successColor} size={primaryIconSize} />
            )
        } catch (err) {
            console.error(err)
            toast(
                `Unable to translate information into ${langObject.title}.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
    }

    const onTranslatePress = () => {
        setLanguageSelectorModalOpen(true)
    }

    const onLanguageSelectorModalClose = () => {
        setLanguageSelectorModalOpen(false)
    }

    const onLocalContentToggle = async () => {
        if (!locoEnabled) {
            // LoCo is off; turn it on if location retrieval is successful
            let position: GeolocationPosition | undefined
            try {
                position = await getCurrentLocation()
            } catch (err: any) {
                console.error(err)
                setLocalContentPopoverTextOverride(
                    err.code === 3
                        ? loCoLocationFailedMessage
                        : loCoLocationDeniedMessage
                )
                setShowNoLocalContentPopover(true)
                return
            }
            try {
                setLocalContentPopoverTextOverride(undefined)
                // User granted location permission
                storeAsync('@loCoOn', 'true')
                setLocoEnabled(true)

                // Re-pull hazard content WITH coordinates passed in
                const { latitude, longitude } = position.coords
                const urlPieces = location.pathname.split('/')
                const identifier = urlPieces[urlPieces.length - 1]
                const h = await getHazard(identifier, language, [
                    latitude,
                    longitude,
                ])
                if (h.local && !h.creator) {
                    for (const cb of h.content_blocks) {
                        if (!cb.local) continue
                        const boosters: Set<SupportedContentBooster> =
                            new Set<SupportedContentBooster>(cb.toggles)
                        boosters.add('Local')
                        cb.toggles = [...Array.from(boosters)]
                    }
                }
                const hasLocalContent = h.content_blocks.some(
                    (cb) => cb.local || cb.local_content.length > 0
                )
                if (hasLocalContent && !boosterStack.includes('Local')) {
                    onBoosterPress('Local')
                }
                setHazard(h)
                if (
                    !h.content_blocks.some(
                        (cb) => cb.local || cb.local_content.length > 0
                    )
                ) {
                    setLocoEnabled(false)
                    setShowNoLocalContentPopover(true)
                    dispatch(getHazardsThunk({ language, position }))
                        .then(unwrapResult)
                        .then(({ hazards: res }) => {
                            const loCoOn = readAsync('@loCoOn') === 'true'
                            if (loCoOn && !res.some((h) => h.local)) {
                                storeAsync('@loCoOn', 'false')
                            }
                        })
                        .catch(console.error)
                }
            } catch (err: any) {
                console.error(err)
                setLocoEnabled(false)
                toast(
                    'Unable to retrieve local information',
                    <IoMap color={errorColor} size={primaryIconSize} />
                )
            }
        } else {
            // LoCo is on; turn it off
            storeAsync('@loCoOn', 'false')
            const urlPieces = location.pathname.split('/')
            const identifier = urlPieces[urlPieces.length - 1]
            getHazard(identifier, language)
                .then((h) => {
                    if (boosterStack.includes('Local')) {
                        onBoosterPress('Local')
                    }
                    setHazard(h)
                })
                .catch(console.error)
            setLocoEnabled(false)
        }
    }

    const handleOpenCommunitySponsors = async () => {
        if (
            !hazard ||
            !hazard.community_sponsors_block_id ||
            !userFacingActiveStage
        )
            return
        await onCommunitySponsorsOpen(userFacingActiveStage)
        if (activeStage === 'Before') {
            setOpenPrepareBlock(hazard.community_sponsors_block_id)
        } else if (activeStage === 'During') {
            setOpenReactBlock(hazard.community_sponsors_block_id)
        } else {
            setOpenRecoverBlock(hazard.community_sponsors_block_id)
        }

        updateUrlParamsWithOpenBlockId(hazard.community_sponsors_block_id)
    }

    const handleOpenPromotedProducts = async () => {
        if (!hazard || !hazard.promoted_products_block_id) return
        await onPromotedProductsOpen(
            userFacingActiveStage ?? hazard.default_stage,
            user
        )
        if (activeStage === 'Before') {
            setOpenPrepareBlock(hazard.promoted_products_block_id)
        } else if (activeStage === 'During') {
            setOpenReactBlock(hazard.promoted_products_block_id)
        } else {
            setOpenRecoverBlock(hazard.promoted_products_block_id)
        }

        updateUrlParamsWithOpenBlockId(hazard.promoted_products_block_id)
    }

    const onBookmarkPress = () =>
        new Promise<void>((resolve) => {
            if (hazard) {
                if (bookmarkID) {
                    dispatch(removeBookmarkThunk(bookmarkID))
                        .then(() => {
                            logEvent('REMOVE_BOOKMARK', {
                                hazard_id: hazard.id,
                            })
                            toast(
                                `Removed ${hazard.name[language]} from your bookmarked hazards.`,
                                <IoBookmarkOutline
                                    color={successColor}
                                    size={primaryIconSize}
                                />
                            )
                        })
                        .catch(console.error)
                        .finally(resolve)
                } else {
                    dispatch(addBookmarkThunk(hazard.id))
                        .then(() => {
                            logEvent('ADD_BOOKMARK', {
                                hazard_id: hazard.id,
                            })
                            toast(
                                `Added ${hazard.name[language]} to your bookmarked hazards.`,
                                <IoBookmark
                                    color={successColor}
                                    size={primaryIconSize}
                                />
                            )
                        })
                        .catch(console.error)
                        .finally(resolve)
                }
            } else {
                resolve()
            }
        })

    const onShare = async () => {
        if (hazard) {
            logEvent('SHARE_HAZARD', {
                hazard_id: hazard.id,
                name: hazard.name[LanguageCode.ENGLISH],
                language,
            })
            const link = `${hazGuideQuickUriBase}/${hazard.id}`
            clipboard
                .writeText(link)
                .then(() => {
                    toast(
                        'Link copied!',
                        <IoIosLink
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                })
                .catch(console.error)
        }
    }

    const onSponsorLinkPress = async (sponsor_id: number, url: string) => {
        if (hazard) {
            const sponsor = communitySponsors.find(
                (spon) => spon.id === sponsor_id
            )
            logEvent('CLICK_COMMUNITY_SPONSOR_LINK', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                sponsor_id,
                sponsor_name: sponsor?.name,
                language,
                url,
            }) // Log the user opening a community sponsor link
            window.open(url, '_blank', 'noopener') // Open the link in a new tab
        }
    }

    const onSponsorHelpIconPress = () => {
        if (hazard) {
            logEvent('CLICK_COMMUNITY_SPONSORS_HELP_ICON', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                language,
            }).catch(console.error)
        }
    }

    const onSponsorAreaHover = (sponsor_id: number) => {
        if (hazard) {
            const sponsor = communitySponsors.find(
                (spon) => spon.id === sponsor_id
            )
            logEvent('HOVER_COMMUNITY_SPONSOR', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                sponsor_id,
                sponsor_name: sponsor?.name,
                language,
            }).catch(console.error)
        }
    }

    const onSponsorAreaClick = (sponsor_id: number) => {
        if (hazard) {
            const sponsor = communitySponsors.find(
                (spon) => spon.id === sponsor_id
            )
            logEvent('CLICK_COMMUNITY_SPONSOR', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                sponsor_id,
                sponsor_name: sponsor?.name,
                language,
            }).catch(console.error)
        }
    }

    const onSponsorSupplementalLinkClick = (
        sponsor_id: number,
        url: string
    ) => {
        if (hazard) {
            const sponsor = communitySponsors.find(
                (spon) => spon.id === sponsor_id
            )
            logEvent('CLICK_COMMUNITY_SPONSOR_SUPPLEMENTAL_LINK', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                sponsor_id,
                sponsor_name: sponsor?.name,
                language,
                url,
            })
        }
        window.open(url, '_blank', 'noopener') // Open the link in a new tab
    }

    const onPromotedProductAreaHover = (product_id: number) => {
        if (!hazard || !userFacingActiveStage) return
        const productList =
            userFacingActiveStage === 'Recover'
                ? promotedRecoveryProducts
                : promotedPrepProducts
        const product = productList.find((p) => p.id === product_id)
        logEvent('HOVER_PROMOTED_PRODUCT', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            product_id,
            product_name: product?.name,
            stage: userFacingActiveStage,
            language,
            via: 'hazard',
            retailer_id: product?.retailer.id,
            retailer_name: product?.retailer.name,
            affiliate_link: !!product?.affiliate_link,
        }).catch(console.error)
    }

    const onPromotedProductAreaClick = (product_id: number) => {
        if (!hazard || !userFacingActiveStage) return
        const productList =
            userFacingActiveStage === 'Recover'
                ? promotedRecoveryProducts
                : promotedPrepProducts
        const product = productList.find((p) => p.id === product_id)
        logEvent('CLICK_PROMOTED_PRODUCT_AREA', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            product_id,
            product_name: product?.name,
            stage: userFacingActiveStage,
            language,
            via: 'hazard',
            retailer_id: product?.retailer.id,
            retailer_name: product?.retailer.name,
            affiliate_link: !!product?.affiliate_link,
        }).catch(console.error)
    }

    const onPromotedProductClick = (product_id: number, url: string) => {
        if (!hazard || !userFacingActiveStage) return
        const productList =
            userFacingActiveStage === 'Recover'
                ? promotedRecoveryProducts
                : promotedPrepProducts
        const product = productList.find((p) => p.id === product_id)
        logEvent('CLICK_PROMOTED_PRODUCT', {
            via: 'hazard',
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            product_id,
            product_name: product?.name,
            stage: userFacingActiveStage,
            language,
            retailer_id: product?.retailer.id,
            retailer_name: product?.retailer.name,
            affiliate_link: !!product?.affiliate_link,
        }).catch(console.error)

        window.open(url, '_blank', 'noopener') // Open the link in a new tab
    }

    const onPromotedProductHelpIconPress = () => {
        if (!hazard || !userFacingActiveStage) return
        logEvent('CLICK_PROMOTED_PRODUCTS_HELP_ICON', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            stage: userFacingActiveStage,
            language,
        }).catch(console.error)
    }

    const onPromotedProductBookmarkPress = async (product_id: number) => {
        if (!hazard) return
        if (!user) {
            setShowPromotedProductsLoginWall(true)
            return
        }

        const productList =
            userFacingActiveStage === 'Recover'
                ? promotedRecoveryProducts
                : promotedPrepProducts

        const product = productList.find((p) => p.id === product_id)
        if (!product) return

        const promoted_prep_products = [...promotedPrepProducts]
        const promoted_recovery_products = [...promotedRecoveryProducts]

        // Check current state of product
        const bookmarkedPrepIndex = promoted_prep_products.findIndex(
            (p) => p.bookmarked && p.id === product_id
        )
        const bookmarkedRecoveryIndex = promoted_recovery_products.findIndex(
            (p) => p.bookmarked && p.id === product_id
        )

        if (bookmarkedPrepIndex >= 0 || bookmarkedRecoveryIndex >= 0) {
            // Product is bookmarked; delete it and unbookmark on the frontend
            try {
                await deleteProductBookmark(product_id)
                toast(
                    `Removed item from your bookmarked products.`,
                    <IoBookmark color={successColor} size={primaryIconSize} />
                )
                if (bookmarkedPrepIndex >= 0) {
                    promoted_prep_products[bookmarkedPrepIndex].bookmarked =
                        false
                    promoted_prep_products[bookmarkedPrepIndex].num_bookmarks--
                }
                if (bookmarkedRecoveryIndex >= 0 && bookmarkedPrepIndex < 0) {
                    promoted_recovery_products[
                        bookmarkedRecoveryIndex
                    ].bookmarked = false
                    promoted_recovery_products[bookmarkedRecoveryIndex]
                        .num_bookmarks--
                }
            } catch (err) {
                console.error(err)
                toast(
                    'Unable to remove bookmark. Please try again.',
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        } else if (bookmarkedPrepIndex < 0 && bookmarkedRecoveryIndex < 0) {
            // Product is NOT bookmarked; add it and bookmark on the frontend

            logEvent('BOOKMARK_PROMOTED_PRODUCT', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                product_id,
                product_name: product?.name,
                stage: userFacingActiveStage,
                language,
                retailer_id: product?.retailer.id,
                retailer_name: product?.retailer.name,
                affiliate_link: !!product?.affiliate_link,
            })

            try {
                await addProductBookmark(product_id)
                toast(
                    `Added item to your bookmarked products.`,
                    <IoBookmark color={successColor} size={primaryIconSize} />
                )
                const prepProductIdx = promoted_prep_products.findIndex(
                    (p) => p.id === product_id
                )
                if (prepProductIdx >= 0) {
                    promoted_prep_products[prepProductIdx].bookmarked = true
                    promoted_prep_products[prepProductIdx].num_bookmarks++
                }
                const recoveryProductIdx = promoted_recovery_products.findIndex(
                    (p) => p.id === product_id
                )
                if (recoveryProductIdx >= 0 && prepProductIdx < 0) {
                    promoted_recovery_products[recoveryProductIdx].bookmarked =
                        true
                    promoted_recovery_products[recoveryProductIdx]
                        .num_bookmarks++
                }
            } catch (err) {
                console.error(err)
                toast(
                    'Unable to bookmark product. Please try again.',
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        }
        setPromotedPrepProducts(promoted_prep_products)
        setPromotedRecoveryProducts(promoted_recovery_products)
    }

    const onPromotedProductShare = async (product_id: number, url: string) => {
        if (!hazard || !userFacingActiveStage) return
        const productList =
            userFacingActiveStage === 'Recover'
                ? promotedRecoveryProducts
                : promotedPrepProducts
        const product = productList.find((p) => p.id === product_id)
        logEvent('SHARE_PROMOTED_PRODUCT', {
            hazard_id: hazard.id,
            hazard_name: hazard.name[LanguageCode.ENGLISH],
            product_id,
            product_name: product?.name,
            url,
            language,
            via: 'hazard',
            retailer_id: product?.retailer.id,
            retailer_name: product?.retailer.name,
            stage: userFacingActiveStage,
            affiliate_link: !!product?.affiliate_link,
        })
        clipboard
            .writeText(url)
            .then(() => {
                toast(
                    'Link copied!',
                    <IoIosLink color={successColor} size={primaryIconSize} />
                )
            })
            .catch(console.error)
    }

    const allowDisablingAutoShowNoLoco = async (): Promise<void> => {
        if (user) {
            const tutorials_shown = new Set<string>(user.tutorials_shown)
            tutorials_shown.add(Tutorial.DISABLE_AUTO_SHOW_NO_LOCO)
            dispatch(
                saveProfileChangesThunk({
                    tutorials_shown: Array.from(tutorials_shown),
                })
            )
        } else {
            storeAsync(Tutorial.DISABLE_AUTO_SHOW_NO_LOCO, 'true')
        }
    }

    const onCloseNoLocalContentPopover: React.MouseEventHandler = (e) => {
        allowDisablingAutoShowNoLoco()
        setShowNoLocalContentPopover(false)
        setTimeout(() => {
            setLocalContentPopoverTextOverride(undefined)
        }, 750)
    }

    const onLocalContentAdditionHover = (
        content_block_id: number,
        creator_id?: number
    ): void => {
        if (hazard) {
            const cb = hazard.content_blocks.find(
                (c) => c.id === content_block_id
            )
            logEvent('HOVER_LOCO_TIER_1', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                content_block_id,
                content_block_title: cb?.title[LanguageCode.ENGLISH],
                creator_id,
                creator_name: cb?.creator?.name,
                language,
            })
        }
    }

    const onLocalContentAdditionLinkClick = (
        url: string,
        content_block_id: number,
        creator_id?: number
    ): void => {
        if (hazard) {
            const cb = hazard.content_blocks.find(
                (c) => c.id === content_block_id
            )
            logEvent('CLICK_LOCO_TIER_1_LINK', {
                hazard_id: hazard.id,
                hazard_name: hazard.name[LanguageCode.ENGLISH],
                content_block_id,
                content_block_title: cb?.title[LanguageCode.ENGLISH],
                creator_id,
                creator_name: cb?.creator?.name,
                language,
                url,
            })
        }
        window.open(url, '_blank', 'noopener') // Open the link in a new tab
    }

    const onPrepCheckBlockClick = (id: number): void => {
        navigate(`/prep-checks/${id}?from=${location.pathname}`)
    }

    const onPrepCheckShare = (id: number): void => {
        const prepCheck = prepChecks.find((pc) => pc.id === id)
        if (!prepCheck) return

        logEvent('SHARE_PREP_CHECK', {
            prep_check_id: id,
            name: prepCheck.title,
            page: 'Hazard',
            hazard_id: hazard?.id,
            language,
        })
        const link = `${hazGuideQuickUriBase}/pc/${id}`
        clipboard
            .writeText(link)
            .then(() => {
                toast(
                    'Link copied!',
                    <IoIosLink color={successColor} size={primaryIconSize} />
                )
            })
            .catch(console.error)
    }

    const scrollY = React.useRef(0)
    React.useEffect(() => {
        if (mediumWindowOrLarger) {
            if (boostersExpanded) setBoostersExpanded(false)
            return
        }
        const closeBoosterViewOnScrollDown = () => {
            if (!boostersExpanded || !mediumWindowOrLarger)
                if (scrollY.current < window.scrollY) {
                    setBoostersExpanded(false)
                }
            scrollY.current = window.scrollY
        }

        window.addEventListener('scroll', closeBoosterViewOnScrollDown)
        return () => {
            window.removeEventListener('scroll', closeBoosterViewOnScrollDown)
        }
    }, [boostersExpanded, mediumWindowOrLarger])

    const onLogin = async () => {
        const redirectUrl = `${frontendUriBase}/hazards/${hazard?.id ?? ''}`
        await login(redirectUrl)
    }

    const promotedProductList = React.useMemo(() => {
        const sortProducts = (a: PromotedProductDTO, b: PromotedProductDTO) => {
            if (!a.affiliate_link && b.affiliate_link) return -1
            if (a.affiliate_link && !b.affiliate_link) return 1
            return 0
        }
        if (activeStage === 'After') {
            return promotedRecoveryProducts.sort(sortProducts)
        } else {
            return promotedPrepProducts.sort(sortProducts)
        }
    }, [activeStage, promotedRecoveryProducts, promotedPrepProducts])

    return (
        <IndvHazardPageTemplate
            name={
                hazard?.name[language] ||
                hazard?.name[LanguageCode.ENGLISH] ||
                ''
            }
            id={hazard?.id}
            slug={hazard?.slug}
            activeStage={activeStage}
            blocks={activeBlocks}
            selectedBlock={
                activeStage === 'Before'
                    ? openPrepareBlock
                    : activeStage === 'During'
                    ? openReactBlock
                    : openRecoverBlock
            }
            languages={hazard?.translations || []}
            selectedLanguage={
                hazard &&
                hazard.translations.includes(language) &&
                hazard.name[language]
                    ? language
                    : LanguageCode.ENGLISH
            }
            bookmarked={bookmarkID !== undefined}
            boosters={boosters}
            activeBoosters={boosterStack}
            availableBoosters={availableBoosters}
            languageSelectorModalOpen={languageSelectorModalOpen}
            onLinkPress={onLinkPress}
            onLocalContentToggle={onLocalContentToggle}
            locoEnabled={locoEnabled}
            onSponsorLinkPress={onSponsorLinkPress}
            onBlockPress={onBlockPress}
            onBlockHover={onBlockHover}
            onPdfPress={(id, slug) => onPdfPress(id, slug, language)}
            onBoosterPress={onBoosterPress}
            onBoosterReset={onBoosterReset}
            onStagePress={onStagePress}
            onBookmark={onBookmarkPress}
            onTranslatePress={onTranslatePress}
            onShare={onShare}
            onLanguageChange={onLanguageChange}
            onLanguageSelectorModalClose={onLanguageSelectorModalClose}
            transformLinkUri={transformLinkUri}
            loading={
                props.loading ||
                !hazardRetrieved ||
                !bookmarksRetrieved ||
                !blocksReady
            }
            /** Community sponsor props */
            communitySponsors={communitySponsors}
            communitySponsorBlockId={hazard?.community_sponsors_block_id}
            communitySponsorsOpen={communitySponsorsOpen}
            onCommunitySponsorsOpen={handleOpenCommunitySponsors}
            hasCommunitySponsors={!!hazard?.has_sponsors}
            onSponsorAreaHover={onSponsorAreaHover}
            onSponsorAreaClick={onSponsorAreaClick}
            onSponsorSupplementalLinkPress={onSponsorSupplementalLinkClick}
            onSponsorHelpIconPress={onSponsorHelpIconPress}
            onCommunitySponsorsBlockHover={onCommunitySponsorsBlockHover}
            /** Promoted product props */
            promotedProducts={promotedProductList}
            promotedProductBlockId={hazard?.promoted_products_block_id}
            promotedProductsOpen={promotedProductsOpen}
            onPromotedProductsOpen={handleOpenPromotedProducts}
            hasPromotedProducts={
                activeStage === 'During'
                    ? false
                    : activeStage === 'After'
                    ? !!hazard?.has_promoted_recovery_products
                    : !!hazard?.has_promoted_prep_products
            }
            onPromotedProductAreaHover={onPromotedProductAreaHover}
            onPromotedProductAreaClick={onPromotedProductAreaClick}
            onPromotedProductClick={onPromotedProductClick}
            onPromotedProductHelpIconPress={onPromotedProductHelpIconPress}
            onPromotedProductBookmarkPress={onPromotedProductBookmarkPress}
            onPromotedProductsBlockHover={onPromotedProductsBlockHover}
            onPromotedProductShare={onPromotedProductShare}
            creator={hazard?.creator}
            stagesWithLoCo={(
                ['Before', 'During', 'After'] as SupportedHazardStage[]
            ).filter((stage) =>
                hazard?.content_blocks.some(
                    (cb) => cb.stage === stage && cb.local
                )
            )}
            showNoLocalContentPopover={showNoLocalContentPopover}
            onCloseNoLocalContentPopover={onCloseNoLocalContentPopover}
            localContentPopoverTextOverride={localContentPopoverTextOverride}
            onLocalContentAdditionHover={onLocalContentAdditionHover}
            onLocalContentAdditionLinkClick={onLocalContentAdditionLinkClick}
            onPrepCheckBlockClick={onPrepCheckBlockClick}
            onPrepCheckShare={onPrepCheckShare}
            boostersExpanded={boostersExpanded}
            onBoosterViewToggle={setBoostersExpanded}
            /** Promoted Products Login Wall */
            showPromotedProductsLoginWall={showPromotedProductsLoginWall}
            togglePromotedProductsLoginWall={setShowPromotedProductsLoginWall}
            onLogin={onLogin}
        />
    )
}
