import {
    getLangObjects,
    LanguageCode,
    LoggedPage,
    NativeLanguageDTO,
    PrepCheckQuestionSubmission,
    PrepCheckStatus,
    PrepCheckUserSubmissionDTO,
} from '@hazadapt-git/public-core-base'
import { Error as ErrorIcon } from '@mui/icons-material'
import React, { FC } from 'react'
import qs from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import voca from 'voca'

import {
    PrepCheckCardDropItemProps,
    PrepCheckPageTemplate,
} from '../components'
import { PageProps } from '../lib/entities'
import {
    getPrepCheckThunk,
    getPrepCheckUserStatsThunk,
    saveProfileChangesThunk,
    switchLanguageThunk,
} from '../lib/slices'
import { RootState, useAppDispatch, useAppSelector } from '../lib/store'
import {
    errorColor,
    primaryIconSize,
    successColor,
} from '../lib/styles/universal'
import { logEvent, login, toast } from '../lib/utils'
import {
    getEmergencyItemsForPrepCheck,
    getOneDraftSubmission,
    savePrepCheckSubmission,
} from '../lib/utils/prep'
import { IoCloudDownloadOutline, IoLanguage } from 'react-icons/io5'

interface PrepCheckPageProps extends PageProps {}

export const PrepCheckPage: FC<PrepCheckPageProps> = (
    props: PrepCheckPageProps
) => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    // NOTE: This only becomes true when prep check retrieval dispatch has completed.
    // This makes sure the user only sees the current prep check on this page.
    const loadedSubmission = React.useRef<boolean>(false)

    const { language, user, profileReady } = useAppSelector(
        (state: RootState) => state.profile
    )
    const { active_prep_check: prepCheck, stats } = useAppSelector(
        (state: RootState) => state.prep
    )
    const { languages: supportedLanguages } = useAppSelector(
        (state: RootState) => state.hazards
    )
    const [activeQuestionIdx, setActiveQuestionIdx] = React.useState<number>(0)
    const [draftSubmission, setDraftSubmission] =
        React.useState<PrepCheckUserSubmissionDTO>()
    const [answerConfig, setAnswerConfig] = React.useState<
        PrepCheckCardDropItemProps[]
    >([])
    const [
        myRelevantEmergencyItemAnswerIds,
        setMyRelevantEmergencyItemAnswerIds,
    ] = React.useState<number[]>([])
    const [savingInProgress, setSavingInProgress] =
        React.useState<boolean>(false)
    const [savedSuccessfully, setSavedSuccessfully] =
        React.useState<boolean>(true)
    const [languageSelectorModalOpen, setLanguageSelectorModalOpen] =
        React.useState<boolean>(false)

    React.useEffect(() => {
        if (!profileReady) return

        const urlPieces = location.pathname.split('/')
        const identifier = urlPieces[urlPieces.length - 1]
        if (voca.isNumeric(identifier)) {
            // Get data for prep check
            dispatch(getPrepCheckUserStatsThunk(language))
            dispatch(
                getPrepCheckThunk({ id: parseInt(identifier), language })
            ).catch((err) => {
                console.error(err)
                navigate('/prep-checks')
            })
            getOneDraftSubmission(parseInt(identifier), language)
                .then((sub) => {
                    setDraftSubmission(sub)
                    setActiveQuestionIdx(sub.submitted_responses.length - 1)
                })
                .catch((err) => {
                    console.error(err)
                    setActiveQuestionIdx(0)
                    loadedSubmission.current = true
                })
        }
    }, [location.pathname, language, dispatch, profileReady, navigate])

    React.useEffect(() => {
        if (!profileReady) return

        // Page setup
        if (prepCheck) {
            document.title = `${
                prepCheck.title.includes('Prep Check')
                    ? prepCheck.title
                    : `${prepCheck.title} Prep Check`
            } - HazAdapt`
            const searchParams = qs.parse(location.search)
            const fromPath = searchParams.from
            logEvent('OPEN_PAGE', {
                page: LoggedPage.PREP_CHECK,
                language,
                from: Array.isArray(fromPath) ? fromPath[0] : fromPath,
                prep_check_id: prepCheck.id,
            })
        }
    }, [language, prepCheck, profileReady, location.search])

    React.useEffect(() => {
        // Save prep check submission when it's updated
        if (!loadedSubmission.current) {
            loadedSubmission.current = true
        } else if (
            draftSubmission &&
            draftSubmission.submitted_responses.length > 0
        ) {
            setSavingInProgress(true)
            savePrepCheckSubmission({
                ...draftSubmission,
                status: PrepCheckStatus.DRAFT,
            })
                .then(() => {
                    setSavedSuccessfully(true)
                })
                .catch(() => {
                    setSavedSuccessfully(false)
                })
                .finally(() => {
                    setSavingInProgress(false)
                })
        }
    }, [draftSubmission])

    // Get the emergency items the user has for the prep check
    React.useEffect(() => {
        if (!prepCheck) return
        getEmergencyItemsForPrepCheck(prepCheck.id)
            .then(setMyRelevantEmergencyItemAnswerIds)
            .catch(console.error)
    }, [prepCheck])

    React.useEffect(() => {
        // Build answer config object (used for user_answers)
        if (!prepCheck) return
        try {
            const question = prepCheck.questions[activeQuestionIdx]
            if (question.question_type === 'Checkboxes') {
                // answerConfig is PrepCheckCardDropItemProps[], which is not applicable to checkbox-type questions
                setAnswerConfig([])
            } else if (question.question_type === 'TwoD_Grid_Placement') {
                const beforeColumnSize = question.options.filter(
                    (o) => o.best_stage === 'Before'
                ).length
                const duringColumnSize = question.options.filter(
                    (o) => o.best_stage === 'During'
                ).length
                const config: PrepCheckCardDropItemProps[] = []
                try {
                    // Build answer config from existing submission
                    if (!draftSubmission) throw new Error('No draft submission')
                    const response = draftSubmission.submitted_responses.find(
                        (sr) =>
                            sr.question_id ===
                            prepCheck.questions[activeQuestionIdx].id
                    )
                    if (!response)
                        throw new Error('No draft submission for this question')

                    const iter = [...Array(question.options.length)]
                    iter.forEach((_, index) => {
                        const userAnswer = response.user_answer.find(
                            (ua) =>
                                'placement' in ua.answer &&
                                ((ua.answer.placement[0] === 'Before' &&
                                    index < beforeColumnSize &&
                                    index === ua.answer.placement[1] - 1) ||
                                    (ua.answer.placement[0] === 'During' &&
                                        index >= beforeColumnSize &&
                                        index <
                                            beforeColumnSize +
                                                duringColumnSize &&
                                        index - beforeColumnSize ===
                                            ua.answer.placement[1] - 1) ||
                                    (ua.answer.placement[0] === 'After' &&
                                        index >=
                                            beforeColumnSize +
                                                duringColumnSize &&
                                        index -
                                            (beforeColumnSize +
                                                duringColumnSize) ===
                                            ua.answer.placement[1] - 1))
                        )
                        const placedAnswer = userAnswer
                            ? question.options.find(
                                  (o) => o.id === userAnswer.answer_id
                              )
                            : undefined
                        config.push({
                            index,
                            active: false,
                            questionType: 'TwoD_Grid_Placement',
                            priority:
                                (index < beforeColumnSize
                                    ? index
                                    : index <
                                      beforeColumnSize + duringColumnSize
                                    ? index - beforeColumnSize
                                    : index -
                                      (beforeColumnSize + duringColumnSize)) +
                                1,
                            prepStage:
                                index < beforeColumnSize
                                    ? 'Before'
                                    : index <
                                      beforeColumnSize + duringColumnSize
                                    ? 'During'
                                    : 'After',
                            answerOption: placedAnswer,
                        })
                    })
                } catch (err) {
                    // Build answer config from scratch
                    const iter = [...Array(question.options.length)]
                    iter.forEach((_, index) => {
                        config.push({
                            index,
                            active: false,
                            questionType: 'TwoD_Grid_Placement',
                            priority:
                                (index < beforeColumnSize
                                    ? index
                                    : index <
                                      beforeColumnSize + duringColumnSize
                                    ? index - beforeColumnSize
                                    : index -
                                      (beforeColumnSize + duringColumnSize)) +
                                1,
                            prepStage:
                                index < beforeColumnSize
                                    ? 'Before'
                                    : index <
                                      beforeColumnSize + duringColumnSize
                                    ? 'During'
                                    : 'After',
                            answerOption: undefined,
                        })
                    })
                }
                setAnswerConfig(config)
            } else {
                const config: PrepCheckCardDropItemProps[] = []
                try {
                    // Build answer config from existing submission
                    if (!draftSubmission) throw new Error('No draft submission')
                    const response = draftSubmission.submitted_responses.find(
                        (sr) =>
                            sr.question_id ===
                            prepCheck.questions[activeQuestionIdx].id
                    )
                    if (!response)
                        throw new Error('No draft submission for this question')

                    question.options.forEach((_, index) => {
                        const userAnswer = response.user_answer.find(
                            (ua) =>
                                'order' in ua.answer &&
                                ua.answer.order === index + 1
                        )
                        const placedAnswer = userAnswer
                            ? question.options.find(
                                  (o) => o.id === userAnswer.answer_id
                              )
                            : undefined
                        config.push({
                            index,
                            active: index === response.user_answer.length,
                            questionType: 'OneD_Ordered_List',
                            priority: index + 1,
                            answerOption: placedAnswer,
                        })
                    })
                    setAnswerConfig(config)
                } catch (err) {
                    // Build answer config from scratch
                    question.options.forEach((_, index) => {
                        config.push({
                            index,
                            active: index === 0,
                            questionType: 'OneD_Ordered_List',
                            priority: index + 1,
                            answerOption: undefined,
                        })
                    })
                    setAnswerConfig(config)
                }
            }
        } catch (err) {
            console.error(err)
        }
    }, [activeQuestionIdx, prepCheck, draftSubmission])

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [activeQuestionIdx])

    const onPreviousQuestionClick = () => {
        setActiveQuestionIdx((idx) => Math.max(0, idx - 1))
    }

    const onNextQuestionClick = () => {
        if (!prepCheck) return

        // Get current question
        const activeQuestion = prepCheck.questions.at(activeQuestionIdx)
        if (!activeQuestion) return

        const newActiveQuestionIdx = Math.min(
            prepCheck.questions.length - 1,
            activeQuestionIdx + 1
        )
        if (
            newActiveQuestionIdx < 0 ||
            newActiveQuestionIdx >= prepCheck.questions.length
        )
            return
        const newActiveQuestion = prepCheck.questions[newActiveQuestionIdx]

        // Get current draft submission, initializing a new one if it doesn't already exist
        let draft_submission: PrepCheckUserSubmissionDTO
        if (draftSubmission) {
            draft_submission = { ...draftSubmission }
        } else {
            draft_submission = {
                prep_check_id: prepCheck.id,
                status: PrepCheckStatus.DRAFT,
                submitted_responses: [
                    {
                        question_id: activeQuestion.id,
                        user_answer: [],
                    },
                ],
            }
        }

        // Initialize next question's user answer list and save so that on next refresh,
        // the user would be auto-dropped into this next question.
        const responseAlreadyExists = draft_submission.submitted_responses.some(
            (sr) => sr.question_id === newActiveQuestion.id
        )

        if (!responseAlreadyExists) {
            if (newActiveQuestion.link_to_emergency_items) {
                // Build with emergency items
                const response: PrepCheckQuestionSubmission = {
                    question_id: newActiveQuestion.id,
                    user_answer: [],
                }
                for (const answer_id of myRelevantEmergencyItemAnswerIds) {
                    const inQuestion = newActiveQuestion.options.some(
                        (o) => o.id === answer_id
                    )
                    if (!inQuestion) continue

                    response.user_answer.push({
                        answer_id,
                        answer: {
                            id: answer_id,
                            checked: true,
                        },
                    })
                }
                draft_submission.submitted_responses.push(response)
            } else {
                draft_submission.submitted_responses.push({
                    question_id: newActiveQuestion.id,
                    user_answer: [],
                })
            }
            setDraftSubmission(draft_submission)
        }

        setActiveQuestionIdx(newActiveQuestionIdx)
    }

    const onDoneClick = async (): Promise<void> => {
        if (!prepCheck || !draftSubmission) return
        try {
            await savePrepCheckSubmission({
                ...draftSubmission,
                status: PrepCheckStatus.SUBMITTED,
            })
            const urlPieces = location.pathname.split('/').slice(1)
            const identifier = urlPieces.at(-1)
            if (identifier && voca.isNumeric(identifier)) {
                navigate(`/prep-checks/${parseInt(identifier)}/complete`)
            }
        } catch (err) {
            console.error(err)
            toast(
                'There was an error saving your prep check submission.',
                <ErrorIcon
                    htmlColor={errorColor}
                    sx={{ fontSize: primaryIconSize }}
                />
            )
        }
    }

    const onLogin = async () => {
        await login()
    }

    const onCloseLoginWall = () => {
        if (location.key === 'default') {
            // user arrived via link or direct URL input, route to homepage
            navigate('/')
        } else {
            // user arrived from within the app, return to hazard
            navigate(-1)
        }
    }

    const shouldForceShowExplainer = (): boolean => {
        if (!prepCheck) return false
        switch (prepCheck.questions[activeQuestionIdx].question_type) {
            case 'TwoD_Grid_Placement': {
                return user
                    ? !user.tutorials_shown.includes('TwoD_Grid_Placement')
                    : false
            }
            case 'Checkboxes': {
                return user
                    ? !user.tutorials_shown.includes('Checkboxes')
                    : false
            }
            case 'OneD_Ordered_List': {
                return user
                    ? !user.tutorials_shown.includes('OneD_Ordered_List')
                    : false
            }
            default: {
                return false
            }
        }
    }

    const onQuestionExplainerClose = () => {
        if (!prepCheck || !user) return
        const tutorials_shown = new Set<string>(user.tutorials_shown)
        switch (prepCheck.questions[activeQuestionIdx].question_type) {
            case 'TwoD_Grid_Placement': {
                tutorials_shown.add('TwoD_Grid_Placement')
                break
            }
            case 'Checkboxes': {
                tutorials_shown.add('Checkboxes')
                break
            }
            case 'OneD_Ordered_List': {
                tutorials_shown.add('OneD_Ordered_List')
                break
            }
            default: {
                break
            }
        }
        dispatch(
            saveProfileChangesThunk({
                tutorials_shown: Array.from(tutorials_shown),
            })
        )
    }

    const onLanguageChange = async (language: LanguageCode) => {
        if (!prepCheck) return
        const langObject: NativeLanguageDTO = getLangObjects([language])[0]
        setLanguageSelectorModalOpen(false)
        toast(
            `Downloading Prep Check in ${langObject.title}.`,
            <IoCloudDownloadOutline
                color={successColor}
                size={primaryIconSize}
            />
        )
        try {
            await dispatch(switchLanguageThunk({ lang: language }))
            await dispatch(getPrepCheckThunk({ id: prepCheck.id, language }))
            toast(
                `Prep Check has been translated into ${langObject.title}.`,
                <IoLanguage color={successColor} size={primaryIconSize} />
            )
        } catch (err) {
            console.error(err)
            toast(
                `Unable to download Prep Check in ${langObject.title}.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
    }

    const onTranslateClick: React.MouseEventHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setLanguageSelectorModalOpen(true)
    }

    const onLanguageSelectorModalClose = () => {
        setLanguageSelectorModalOpen(false)
    }

    return prepCheck &&
        loadedSubmission.current &&
        activeQuestionIdx < prepCheck.questions.length ? (
        <PrepCheckPageTemplate
            prepCheckId={prepCheck.id}
            name={
                prepCheck.title.includes('Prep Check')
                    ? prepCheck.title
                    : `${prepCheck.title} Prep Check`
            }
            icon={prepCheck.light_icon}
            userStats={stats}
            language={language}
            submission={draftSubmission}
            userAnswers={answerConfig}
            question={prepCheck.questions[activeQuestionIdx]}
            questionNumber={activeQuestionIdx + 1}
            totalQuestions={prepCheck.questions.length}
            onPreviousQuestionClick={onPreviousQuestionClick}
            onNextQuestionClick={onNextQuestionClick}
            onDoneClick={onDoneClick}
            updateSubmission={setDraftSubmission}
            showLoginWall={user === null}
            onCloseLoginWall={onCloseLoginWall}
            onLogin={onLogin}
            showTutorial={shouldForceShowExplainer()}
            onQuestionExplainerClose={onQuestionExplainerClose}
            savingInProgress={savingInProgress}
            savedSuccessfully={savedSuccessfully}
            loading={props.loading}
            languages={supportedLanguages}
            selectedLanguage={language}
            onLanguageChange={onLanguageChange}
            onLanguageSelectorModalClose={onLanguageSelectorModalClose}
            languageSelectorModalOpen={languageSelectorModalOpen}
            onTranslateClick={onTranslateClick}
        />
    ) : null
}
