import { colors } from '@hazadapt-git/public-core-base'
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@mui/material'
import React, { FC, ReactNode, useState } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'

interface AnnouncementBannerProps {
    headline: string
    content: ReactNode
    actions?: ReactNode[]
    backgroundColor?: string
}

export const AnnouncementBanner: FC<AnnouncementBannerProps> = ({
    headline,
    content,
    actions,
    backgroundColor,
}) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const lgScreens = useWindowSizeUp('lg')

    const handleAccordionChange = () => {
        setExpanded((prev) => !prev)
    }

    return (
        <Accordion
            expanded={expanded}
            onChange={handleAccordionChange}
            disableGutters
            sx={{
                backgroundColor: backgroundColor,
            }}
        >
            <AccordionSummary
                id="announcement-banner-header"
                aria-controls="announcement-banner-content"
                aria-expanded={expanded}
                sx={{
                    '& .MuiAccordionSummary-content': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    '& .icon': {
                        marginLeft: '1rem',
                        transition: 'transform 0.3s ease',
                        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    },
                }}
            >
                <Typography
                    variant={lgScreens ? 'h4' : 'body1'}
                    component="p"
                    fontWeight="bold"
                >
                    {headline}
                </Typography>
                <IoChevronDown
                    size={'1.5rem'}
                    color={colors.grays.NOIR}
                    className="icon"
                />
            </AccordionSummary>
            <AccordionDetails id="announcement-banner-content">
                {content}
            </AccordionDetails>
            <AccordionActions
                sx={{ paddingBottom: '1rem', paddingRight: '1rem' }}
            >
                {actions}
            </AccordionActions>
        </Accordion>
    )
}
