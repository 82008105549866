import { Button, ButtonProps, Typography } from '@mui/material'
import React, { FC } from 'react'
import { Markdown } from '../atoms'
import { makeStyles } from 'tss-react/mui'
import { useWindowSizeUp } from '../../lib/utils'

interface PlaceholderButtonProps extends ButtonProps {
    text: string
}

interface EmptyContentPlaceholderProps {
    imageProps: React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >
    title?: string
    text: string
    buttonProps?: PlaceholderButtonProps
    textPlacement?: 'top' | 'bottom'
    secondaryText?: string
}

export const EmptyContentPlaceholder: FC<EmptyContentPlaceholderProps> = (
    props: EmptyContentPlaceholderProps
) => {
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const useLocalStyles = makeStyles()({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            maxWidth: '34rem',
            margin: '0 auto',
        },
        text: {
            flex: props.buttonProps ? 1 : 2,
        },
    })
    const { classes: localClasses } = useLocalStyles()

    const onLinkPress = (url: string) => {
        window.open(url, '_blank')
    }
    return (
        <div className={localClasses.container}>
            <div
                style={{
                    display: 'flex',
                    flexDirection:
                        props.textPlacement === 'top'
                            ? 'column-reverse'
                            : 'column',
                }}
            >
                {props.secondaryText && (
                    <Typography>{props.secondaryText}</Typography>
                )}
                <img
                    {...props.imageProps}
                    style={{
                        ...props.imageProps.style,
                        height: '45vh',
                        width: mediumWindowOrLarger ? 'auto' : '40vh',
                    }}
                    alt={props.imageProps.alt}
                />
                <Markdown
                    content={props.text}
                    styleOverrides={{ p: { fontSize: '1.2rem' } }}
                    containerClassName={localClasses.text}
                    onLinkPress={onLinkPress}
                />
                {props.title && (
                    <Typography
                        component="p"
                        variant="h3"
                        fontStyle="normal"
                        fontSize="1.2rem"
                        flex={props.buttonProps ? 1 : 2}
                        paddingBottom="1rem"
                    >
                        {props.title}
                    </Typography>
                )}
            </div>
            {props.buttonProps && (
                <Button
                    {...props.buttonProps}
                    variant="contained"
                    color="error"
                    style={{ ...props.buttonProps.style, flex: 1 }}
                >
                    {props.buttonProps.text}
                </Button>
            )}
        </div>
    )
}
