import { colors, HazardOverview } from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import React, { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import {
    useNavigate,
    useLocation,
    Location,
    Routes,
    Route,
    RoutesProps,
    RouteProps,
    Navigate,
} from 'react-router-dom'

import EmergencyHelpClick from '../assets/icons/EmergencyHelpClick.svg'
// import EmergencyLights from '../assets/icons/EmergencyLights.svg'
import GuideBook from '../assets/icons/GuideBook.svg'
import PrepCheckShieldMini from '../assets/icons/PrepCheckShieldMini.svg'
import { NavBarItem, SiteHeader, Footer, EmergencyButton } from '../components'
import {
    getProfileThunk,
    getProfilePictureThunk,
    getSupportedLanguagesThunk,
    setProfileReady,
    getHazardsThunk,
    getBookmarksThunk,
    getLanguageThunk,
} from '../lib/slices'
import { useAppSelector, RootState, useAppDispatch } from '../lib/store'
import { useStyles } from '../lib/styles/universal'
import {
    searchHazards,
    useWindowSizeUp,
    useWindowSizeExact,
    logEvent,
    logout,
    goToAuthDashboard,
    login,
    getCurrentLocation,
} from '../lib/utils'
import {
    HomePage,
    HazardGuidePage,
    HazardPage,
    BookmarksPage,
    SettingsPage,
    EmergencyHelpPage,
    MyEmergencyItemsPage,
    PrepCheckDonePage,
    PrepCheckLandingPage,
    PrepCheckPage,
    PrepCheckResultsPage,
} from '../pages'
import { IoBookmarks, IoHomeSharp, IoSettingsSharp } from 'react-icons/io5'
import { readAsync } from '../lib/async-storage'
import { unwrapResult } from '@reduxjs/toolkit'
import { GovernmentSourcesUpdateBanner } from '../lib/entities'

interface ScrollToTopProps {
    location: Location
    children: React.ReactNode
}

const ScrollToTop: FC<ScrollToTopProps> = (props: ScrollToTopProps) => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [props.location])

    return <>{props.children}</>
}

export const Router: FC<RoutesProps> = (props: RoutesProps) => {
    const { classes } = useStyles()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const xsWindow = useWindowSizeExact('xs')
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const largeWindowOrLarger = useWindowSizeUp('lg')

    const [loading, setLoading] = React.useState<boolean>(true)
    const [searchQuery, setSearchQuery] = React.useState<string>('')
    const [suggestions, setSuggestions] = React.useState<HazardOverview[]>([])
    const [loggedAppOpen, setLoggedAppOpen] = React.useState<boolean>(false)

    const { hazards } = useAppSelector((state: RootState) => state.hazards)
    const { user, profilePictureURI, language, profileReady } = useAppSelector(
        (state: RootState) => state.profile
    )
    const navigate = useNavigate()
    const activeNav = location.pathname

    React.useEffect(() => {
        if (!profileReady || loggedAppOpen) return
        logEvent('OPEN_APP', { language })
        setLoggedAppOpen(true)
    }, [profileReady, language, loggedAppOpen])

    React.useEffect(() => {
        setSuggestions(hazards)
    }, [hazards])

    React.useEffect(() => {
        setSearchQuery('')
    }, [activeNav])

    React.useEffect(() => {
        dispatch(getProfileThunk())
            .then(() => {
                dispatch(getProfilePictureThunk())
            })
            .catch(console.error)
            .finally(() => {
                dispatch(getLanguageThunk())
                    .then(unwrapResult)
                    .then((lang) => {
                        dispatch(setProfileReady())
                        dispatch(getBookmarksThunk()).finally(() => {
                            let position: GeolocationPosition | undefined
                            const loCoOn = readAsync('@loCoOn') === 'true'
                            getCurrentLocation(!loCoOn)
                                .then((res) => {
                                    position = res
                                })
                                .catch(console.error)
                                .finally(() => {
                                    dispatch(
                                        getHazardsThunk({
                                            language: lang,
                                            position,
                                        })
                                    ).finally(() => {
                                        dispatch(
                                            getSupportedLanguagesThunk()
                                        ).finally(() => {
                                            setLoading(false)
                                        })
                                    })
                                })
                        })
                    })
            })
    }, [dispatch, language])

    const routes: RouteProps[] = [
        {
            path: '/',
            element: <HomePage loading={loading} />,
        },
        {
            path: '/hazards',
            element: <HazardGuidePage loading={loading} />,
        },
        {
            path: '/hazards/:id',
            element: <HazardPage loading={loading} />,
        },
        {
            path: '/hazards/:id/:content_block_id',
            element: <HazardPage loading={loading} />,
        },
        {
            path: '/bookmarks',
            element: <BookmarksPage loading={loading} />,
        },
        {
            path: '/emergency-help',
            element: <EmergencyHelpPage loading={loading} />,
        },
        {
            path: '/settings/*',
            element: <SettingsPage loading={loading} />,
        },
        {
            path: '/prep-checks',
            element: <PrepCheckLandingPage loading={loading} />,
        },
        {
            path: '/prep-checks/:id',
            element: <PrepCheckPage loading={loading} />,
        },
        {
            path: '/prep-checks/:id/complete',
            element: <PrepCheckDonePage loading={loading} />,
        },
        {
            path: '/prep-checks/:id/results',
            element: <PrepCheckResultsPage loading={loading} />,
        },
        {
            path: '/my-emergency-items',
            element: <MyEmergencyItemsPage loading={loading} />,
        },
    ]

    const navBarItems: NavBarItem[] = [
        {
            label: 'Home',
            route: '/',
            icon: <IoHomeSharp color={colors.grays.BLANC} size="1.5rem" />,
        },
        {
            label: 'Hazard Guide',
            route: '/hazards',
            icon: (
                <img
                    src={GuideBook}
                    style={{ width: '1.5rem', height: 'auto' }}
                    alt="Hazard Guide book"
                />
            ),
        },
        {
            label: 'Bookmarks',
            route: '/bookmarks',
            icon: <IoBookmarks color={colors.grays.BLANC} size="1.5rem" />,
        },
        {
            label: 'Prep Checks',
            route: '/prep-checks',
            icon: (
                <img
                    src={PrepCheckShieldMini}
                    style={{ width: '1.5rem', height: 'auto' }}
                    alt="Shield with yellow star"
                />
            ),
        },
        // {
        //     label: 'My Emergency Items',
        //     route: '/my-emergency-items',
        //     icon: <IoMedkit color={colors.grays.BLANC} size="1.5rem" />,
        //     hide: !user,
        // },
        // {
        //     label: 'Should I Call 911?',
        //     route: '/should-i-call-911', // TODO: Create this page
        //     icon: (
        //         <img
        //             src={EmergencyLights}
        //             style={{ width: '1.5rem', height: 'auto' }}
        //             alt="Emergency lights"
        //         />
        //     ),
        //     hide: mediumWindowOrLarger,
        // },
        {
            label: 'Settings',
            route: '/settings',
            icon: <IoSettingsSharp color={colors.grays.BLANC} size="1.5rem" />,
        },
    ]

    React.useEffect(() => {
        searchHazards(hazards, searchQuery.trim().toLowerCase())
            .then(({ hazards: results }) => setSuggestions(results))
            .catch(console.error)
    }, [hazards, searchQuery])

    const onLogoPress = () => {
        if (location.pathname !== '/') {
            window.location.href = '/'
        } else {
            navigate('/', { replace: true })
        }
    }
    const onSuggestionPress = (id: number) => {
        navigate(`/hazards/${id}`)
    }
    const onSearchQuerySubmit = () => {
        navigate(`/hazards?query=${searchQuery}`, {
            replace: true,
        })
    }
    const onEmergencyButtonPress = () => {
        navigate('/emergency-help')
    }

    const routesToHideEmergencyButton = [
        /^\/emergency-help/i,
        /^\/prep-checks/i,
    ]

    const goToProfile = () => {
        navigate('/settings/account')
    }

    const onProfilePress = async () => {
        if (user) {
            navigate('/settings/account')
        } else {
            await login()
        }
    }

    return (
        <ScrollToTop location={location}>
            <div className={classes.root}>
                <SiteHeader
                    searchQuery={searchQuery}
                    onSearchQueryChange={setSearchQuery}
                    options={suggestions}
                    onOptionPress={onSuggestionPress}
                    onSearchQuerySubmit={onSearchQuerySubmit}
                    name={user ? `${user.first_name}` : undefined}
                    onLogoPress={onLogoPress}
                    profilePictureSrc={profilePictureURI}
                    navItems={navBarItems}
                    activeNavItem={activeNav}
                    onNavItemPress={navigate}
                    onEmergencyButtonPress={onEmergencyButtonPress}
                    profileOptions={[
                        {
                            children: 'View Profile',
                            onClick: goToProfile,
                        },
                        {
                            children: 'Manage HazAdapt ID',
                            onClick: goToAuthDashboard,
                        },
                        {
                            children: 'Log Out',
                            onClick: () => logout(),
                            sx: {
                                color: colors.primary.CORAL,
                                fontWeight: 500,
                            },
                        },
                    ]}
                    onProfilePress={onProfilePress}
                />
                <GovernmentSourcesUpdateBanner />
                <div style={{ display: 'flex', position: 'relative' }}>
                    {location.pathname === '/' && mediumWindowOrLarger && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                right: '7rem',
                                top: '2rem',
                                position: 'absolute',
                            }}
                        >
                            <Typography
                                fontWeight={500}
                                fontSize={
                                    largeWindowOrLarger ? '1.25rem' : '1rem'
                                }
                                pb={0}
                                pr="1rem"
                                textAlign="right"
                                sx={{
                                    width: xsWindow ? '50vw' : undefined,
                                }}
                            >
                                Crisis Support Options
                            </Typography>
                            <img
                                src={EmergencyHelpClick}
                                alt="Emergency help guiding mouse click icon"
                            />
                        </div>
                    )}
                    {!routesToHideEmergencyButton.some((r) =>
                        r.test(location.pathname)
                    ) && (
                        <EmergencyButton
                            sx={{
                                position: mediumWindowOrLarger
                                    ? 'absolute'
                                    : 'fixed',
                                right: mediumWindowOrLarger
                                    ? '1.5rem'
                                    : '1.5rem',
                                top: mediumWindowOrLarger ? '1rem' : null,
                                bottom: mediumWindowOrLarger ? null : '1rem',
                                zIndex: 2,
                            }}
                            onClick={onEmergencyButtonPress}
                        />
                    )}
                </div>
                <Routes {...props}>
                    {routes.map((route: RouteProps) => (
                        <Route key={route.path} {...route} />
                    ))}
                    {/* <Route element={<NotFound />} /> */}
                    {user === null && (
                        <Route
                            path="/prep-checks/:id/*"
                            element={<Navigate to="/prep-checks" replace />}
                        />
                    )}
                    <Route path="*" element={<Navigate to="/" replace />} />
                    <Route
                        path="/settings/language"
                        element={
                            <Navigate
                                to="/settings/languages-and-customizations"
                                replace
                            />
                        }
                    />
                </Routes>
                <Footer />
                <Toaster />
            </div>
        </ScrollToTop>
    )
}
