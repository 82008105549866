import { Close } from '@mui/icons-material'
import { Breakpoint, Typography, useMediaQuery } from '@mui/material'
import hotToast, { Renderable } from 'react-hot-toast'
import { ElementContent } from 'react-markdown/lib/ast-to-react'
import voca from 'voca'
import { Feature, FeatureCollection, Point } from 'geojson'

import { theme } from '../styles/universal'
import { PickerItem } from '../entities'
import { store } from '../store'
import { updateCurrentZip } from '../slices/ProfileSlice'
import { getEnvVars } from '../config'
import axios, { AxiosResponse } from 'axios'
import { differenceInMinutes } from 'date-fns'

/**
 * Matches with windows larger than or equal to the given breakpoint
 * @param size Breakpoint
 * @returns boolean
 */
export const useWindowSizeUp = (size: Breakpoint): boolean => {
    return useMediaQuery(theme.breakpoints.up(size))
}

/**
 * Matches with windows smaller than or equal to the given breakpoint
 * @param size Breakpoint
 * @returns boolean
 */
export const useWindowSizeDown = (size: Breakpoint): boolean => {
    return useMediaQuery(theme.breakpoints.down(size))
}

/**
 * Matches with windows exactly the given screen size threshold
 * @param size Breakpoint
 * @returns boolean
 */
export const useWindowSizeExact = (size: Breakpoint): boolean => {
    return useMediaQuery(theme.breakpoints.only(size))
}

/**
 * Matches with windows between the given the breakpoints
 * @param size Breakpoint
 * @returns boolean
 */
export const useWindowSizeBetween = (
    start: Breakpoint,
    end: Breakpoint
): boolean => {
    return useMediaQuery(theme.breakpoints.between(start, end))
}

/**
 * Display a toast message
 * @param message string
 * @param icon React.ReactNode
 */
export const toast = (message: string, icon?: Renderable): void => {
    hotToast(
        (t) => (
            <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={300}
                maxWidth="80vw"
                p={0}
            >
                {message}
                <Close
                    color="secondary"
                    sx={{ cursor: 'pointer', marginLeft: '1rem' }}
                    onClick={() => hotToast.dismiss(t.id)}
                />
            </Typography>
        ),
        {
            icon,
        }
    )
}

export const transformLinkUri = (
    href: string,
    _children: ElementContent[],
    _title: string | null
): string => {
    return voca.lowerCase(href.replace('hazadapt://', '/'))
}

export const getAddress = async (
    location: GeolocationPosition
): Promise<Feature<Point>> => {
    const { geocodingApiKey } = getEnvVars()
    const { latitude, longitude } = location.coords
    const reverseGeocodingEndpoint: string = `https://api.mapbox.com/search/geocode/v6/reverse?latitude=${latitude}&longitude=${longitude}&access_token=${geocodingApiKey}`
    const response: AxiosResponse<FeatureCollection<Point>> = await axios.get(
        reverseGeocodingEndpoint
    )
    const addressFeature = response.data.features.find(
        (f) => f.properties?.feature_type === 'address'
    )
    if (!addressFeature || !addressFeature.properties?.full_address)
        throw new Error('No address found')
    return addressFeature
}

export const getCurrentLocation = (skip = false) =>
    new Promise<GeolocationPosition>((resolve, reject) => {
        if (skip) reject('Skipped location retrieval')
        else {
            navigator.geolocation.getCurrentPosition(
                async (location) => {
                    // Update reverse-geocoded ZIP if last retrieved more than 5 minutes ago
                    const currentZipLastUpdatedStr =
                        store.getState().profile.currentZipLastUpdated
                    const currentZipLastUpdated = currentZipLastUpdatedStr
                        ? new Date(currentZipLastUpdatedStr)
                        : undefined
                    if (
                        !currentZipLastUpdated ||
                        differenceInMinutes(
                            currentZipLastUpdated,
                            new Date()
                        ) >= 5
                    ) {
                        const data = await getAddress(location)
                        const zip: string | undefined =
                            data.properties?.context.postcode.name
                        store.dispatch(updateCurrentZip(zip))
                    }

                    resolve(location)
                },
                (err) => {
                    console.error(err)
                    return reject(err)
                },
                {
                    timeout: 3000,
                    maximumAge: 60000,
                    enableHighAccuracy: false,
                }
            )
        }
    })

/**
 * Sort the items in a picker alphabetically
 * @param a first item to compare
 * @param b second item to compare
 * @returns number
 */
export const sortPickerItemsAlphabetically = (
    a: PickerItem<any>,
    b: PickerItem<any>
) => {
    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
}

/**
 * Capitalizes the first letter of each word in a string
 * @param {string} string
 * @param {Array} noSplit do not split words at the specified character (ex. dashes in hyphenated names)
 * @returns {string}
 */
export const titleCaseString = (string: string, noSplit?: []) => {
    return voca.titleCase(string, noSplit)
}
